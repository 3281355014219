import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { IRouteProps } from 'typings';
import { getPath } from './get-path';

export const usePath = (): IRouteProps['path'] => {
    const { pathname } = useLocation();

    return useMemo(() => getPath(pathname), [pathname]);
};
