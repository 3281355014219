import React, { ReactNode } from 'react';
import styles from './checkbox.module.scss';
import { classNames } from 'utils';
import { Icon } from 'components/images-icons/icon';
import { useToggle } from 'hooks';

interface ICheckbox extends Partial<HTMLDivElement> {
    label?: ReactNode;
}

export const Checkbox = ({ label, className }: ICheckbox) => {
    const { state: checked, toggle } = useToggle(false);

    const CheckboxComponent = ({ className }: { className?: string }) => {
        const checkboxClassName = classNames(styles.container, className, {
            [styles.checked]: checked,
        });

        return (
            <div className={checkboxClassName} onClick={toggle}>
                <input
                    type="checkbox"
                    className={styles.input}
                    defaultChecked={checked}
                />
                <div className={styles.background} />
                <Icon icon="check" className={styles.icon} />
            </div>
        );
    };

    if (!label) {
        return <CheckboxComponent className={className} />;
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <CheckboxComponent />
            <div className={styles.label} onClick={toggle}>
                {label}
            </div>
        </div>
    );
};
