import React, { FunctionComponent, useCallback, ChangeEvent } from 'react';
import styles from './select.module.scss';
import { Icon } from 'components/images-icons/icon';
import { classNames } from 'utils';

interface ISelect<T extends string = string> {
    label?: string;
    onChange?: (
        value: Record<T, string | number>,
        event: ChangeEvent<HTMLSelectElement>
    ) => void;
    name?: string;
    list: { key: string | number; name: string }[];
    addEmptyOption?: boolean;
}

export const Select: FunctionComponent<ISelect> = ({
    label,
    onChange,
    name = 'value',
    list,
    addEmptyOption,
}: ISelect) => {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const nativeEventTarget = event.nativeEvent
                .target as HTMLSelectElement;

            const index = nativeEventTarget.selectedIndex;
            const label = (nativeEventTarget[index] as HTMLOptionElement).text;
            const value = event.target.value;

            return onChange?.({ [name]: value, [`${name}Name`]: label }, event);
        },
        [onChange, name]
    );

    const selectClassName = classNames(styles.container, {
        [styles.withLabel]: !!label,
    });

    return (
        <div className={selectClassName}>
            {!!label && <label className={styles.label}>{label}</label>}
            <select className={styles.select} onChange={handleChange}>
                {!!addEmptyOption && <option value="" />}
                {list.map((item) => (
                    <option key={item.key} value={item.key}>
                        {item.name}
                    </option>
                ))}
            </select>
            <Icon className={styles.icon} icon="angle-down" />
        </div>
    );
};
