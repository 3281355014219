import React, { FunctionComponent, useEffect, useState } from 'react';
import { Logo } from 'components/images-icons/logo';
import { usePath, useToggle, useWindowSize } from 'hooks';
import { Link } from 'react-router-dom';
import { useNavigationActions } from 'store/navigation';
import { matchTestString } from 'utils';
import styles from '../navigation.module.scss';
import { routes } from '../routes';
import { Categories } from './categories';
import { Content } from './content';

export const Container: FunctionComponent = () => {
    const { pathname } = usePath();
    const { breakpoint } = useWindowSize();

    const { openNavigation, closeNavigation } = useNavigationActions();

    const { open, delayedClose } = useToggle(false, {
        delay: 400,
        openCallback: openNavigation,
        closeCallback: closeNavigation,
    });
    const [activeRouteName, setActiveRouteName] = useState<string>('Forside');

    useEffect(() => {
        const active = routes.find((route) =>
            matchTestString(pathname, [
                route.activeCondition,
                ...route.list.map((item) => item.activeCondition),
            ])
        )?.name;

        setActiveRouteName(active || 'Forside');
        closeNavigation();
    }, [pathname]);

    const handleHoverCategory = (name: string) => {
        setActiveRouteName(name);
    };

    return (
        <nav
            className={styles.container}
            onMouseEnter={open}
            onMouseLeave={
                breakpoint !== 'xs' && breakpoint !== 'sm'
                    ? delayedClose
                    : undefined
            }
        >
            <div className={styles.header}>
                <Link to="/">
                    <Logo color="white" shape="horizontal" />
                </Link>
            </div>
            <ul className={styles.categories}>
                {routes.map((route) => (
                    <Categories
                        key={route.name}
                        {...route}
                        handleHoverCategory={handleHoverCategory}
                    />
                ))}
            </ul>
            {routes.map((route) => (
                <Content
                    key={route.name}
                    {...route}
                    activeRouteName={activeRouteName}
                />
            ))}
        </nav>
    );
};
