import { IAutocompleteState } from './typings.autocomplete';

export const initialState: IAutocompleteState = {
    value: '',
    lastWrittenValue: '',
    list: [],
    displayList: [],
    currentActiveItem: null,
    isDropdownOpen: false,
    isLoading: false,
    style: 'search',
    markType: 'none',
    isInitiated: false,
    inFocus: false,
    clearOnSelect: false,
};
