import React from 'react';
import styles from './spinner.module.scss';
import { classNames } from 'utils';
import { IClassName } from 'typings';

export const Spinner = ({ className }: IClassName) => {
    const spinnerClassName = classNames(styles.spinner, className);

    return <div className={spinnerClassName} />;
};
