import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useActions = <Keys extends string>(
    actions: Record<Keys, () => void>
): Record<Keys, () => void> => {
    const dispatch = useDispatch();

    const result = useMemo(() => {
        const remapped: any = {};

        Object.entries(actions).forEach(([key, value]: [string, any]): void => {
            remapped[key] = (): void => dispatch(value());
        });

        return remapped as Record<Keys, () => void>;
    }, [actions]);

    return result;
};
