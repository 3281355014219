import React, { FunctionComponent } from 'react';
import { classNames, Link } from 'utils';
import { useSelector } from 'react-redux';
import styles from './layout.module.scss';
import topbarStyles from './topbar.module.scss';
import { Logo } from 'components/images-icons/logo';
//import { ProfilePicture } from 'components/images-icons/profile-picture';
import { getTheme } from 'store/layout';
import { ToggleTheme } from 'components/behavior/toggle-theme';
import { NavigationButton } from 'components/navigation/navigation';
import { Tooltip } from 'components/overlays/tooltip';

export const Topbar: FunctionComponent = () => {
    const theme = useSelector(getTheme);
    const wrapperClassName = classNames(styles.topbar, topbarStyles.wrapper);

    return (
        <div className={wrapperClassName}>
            <div className={topbarStyles.menuIcon}>
                <NavigationButton />
            </div>
            <div className={topbarStyles.logo}>
                <Link to="/" title="Gå til forsiden" placement="bottom">
                    <Logo
                        shape="horizontal"
                        color={theme === 'light' ? 'black' : 'white'}
                    />
                </Link>
            </div>
            <div className={topbarStyles.toggleTheme}>
                <Tooltip title="Skift tema" placement="left">
                    <ToggleTheme />
                </Tooltip>
            </div>
            {/*<div className={topbarStyles.profile}>
                <Link
                    to="/indstillinger"
                    title="Profil og indstillinger"
                    placement="left"
                    delayed={false}
                >
                    <ProfilePicture size="sm" />
                </Link>
            </div>*/}
        </div>
    );
};
