import { RefObject, useState, useEffect, useRef } from 'react';
import { throttle } from 'utils';
import { isVisible, isVisibleWithDistance } from 'utils/element/is-visible';

const preloadDistance = 500;

interface IUseInView {
    listenOnExit?: boolean;
    usePreload?: boolean;
}

export const useInView = ({ listenOnExit, usePreload }: IUseInView = {}): {
    ref: RefObject<HTMLDivElement>;
    inView: boolean;
    preload: boolean;
} => {
    const ref = useRef<HTMLDivElement>(null);
    const [inView, setInView] = useState<boolean | null>(null);
    const [preload, setPreload] = useState<boolean>(false);
    const blockListener = !listenOnExit && inView === true;

    const setCurrentState = (): void => {
        if (ref.current && !blockListener) {
            if (usePreload) {
                const visibility = isVisibleWithDistance(ref.current);

                setInView(visibility.visible);

                if (
                    !visibility.visible &&
                    !preload &&
                    visibility.distance <= preloadDistance
                ) {
                    setPreload(true);
                }
            } else {
                setInView(isVisible(ref.current));
            }
        }
    };

    const handleScroll = throttle(setCurrentState, 500);

    useEffect(setCurrentState, [ref]);

    useEffect(() => {
        if (!blockListener) {
            const content = document.body.querySelector('#content');

            if (content) {
                content.addEventListener('scroll', handleScroll, false);

                return (): void =>
                    content.removeEventListener('scroll', handleScroll, false);
            }
        }
    }, [ref, listenOnExit, inView, usePreload]);

    return {
        ref,
        inView: inView ?? false,
        preload: (preload || inView) ?? false,
    };
};
