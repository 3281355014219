export const jsonTryParse = <T>(value: string | null): T | null => {
    if (!value) {
        return null;
    }

    try {
        return JSON.parse(value) as T;
    } catch (e) {
        return null;
    }
};
