import { getElementRect } from './get-element-rect';
import { getContent, getContentRect } from './get-content-rect';
import { IRect } from './typings';

export function scrollToElement(
    element: HTMLElement,
    overlay?: Partial<IRect>
): void {
    const content = getContent();
    const contentRect = getContentRect();
    const rect = getElementRect(element, contentRect);

    content.scrollTop = rect.top + contentRect.scrollTop - (overlay?.top ?? 0);
    content.scrollLeft =
        rect.left + contentRect.scrollLeft - (overlay?.left ?? 0);
}
