import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';

export const stateScope = 'tooltips';

interface ITooltip {
    id: string;
    position: {
        top: number;
        left: number;
        width: number;
        height: number;
    };
    content: ReactNode;
    placement: 'left' | 'right' | 'top' | 'bottom';
}

interface IHoveredTooltip {
    id: string;
    timeoutEvent: number;
}

type ITooltipsState = {
    activeTooltips: ITooltip[];
    hovered: IHoveredTooltip[];
};

const initialState: ITooltipsState = {
    activeTooltips: [],
    hovered: [],
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        setHovered: (state, action: PayloadAction<IHoveredTooltip>): void => {
            state.hovered.push(action.payload);
        },
        add: (state, action: PayloadAction<ITooltip>): void => {
            if (
                state.hovered.some(
                    (tooltip) => tooltip.id === action.payload.id
                )
            ) {
                state.hovered = state.hovered.filter(
                    (tooltip) => tooltip.id !== action.payload.id
                );

                state.activeTooltips.push(action.payload);
            }
        },
        remove: (state, action: PayloadAction<ITooltip['id']>): void => {
            const { payload } = action;

            const hovered = state.hovered.filter(
                (tooltip) => tooltip.id === payload
            );

            if (hovered) {
                hovered.forEach((hovered) =>
                    clearTimeout(hovered.timeoutEvent)
                );
                state.hovered = state.hovered.filter(
                    (tooltip) => tooltip.id !== payload
                );
            }

            state.activeTooltips = state.activeTooltips.filter(
                (tooltip) => tooltip.id !== payload
            );
        },
        clearAllTooltips: (): ITooltipsState => {
            return initialState;
        },
    },
});

export const { reducer, actions } = slice;

export const { add, remove, clearAllTooltips } = actions;

export const getState = (state: RootState): ITooltipsState => state[stateScope];

export const reduced = { [stateScope]: reducer };

export const hover = (data: ITooltip, delay: number): AppThunk => (
    dispatch
): void => {
    const timeoutEvent = window.setTimeout(() => {
        dispatch(add(data));
    }, delay);

    dispatch(actions.setHovered({ id: data.id, timeoutEvent }));
};
