import { IConvertState } from './typings.convert';
import { conversionTypes } from 'utils/ingredient/convert';
import { getSelectionTypes } from './actions';

const generateInitialState = (): IConvertState => {
    const categoryTypes = Object.keys(conversionTypes).map((key) => ({
        key,
        name: conversionTypes[key].name,
    }));

    const {
        categoryType,
        categoryList,
        firstSelectedType,
        secondSelectedType,
    } = getSelectionTypes(categoryTypes[0].key);

    return {
        categoryTypes,
        categoryType,
        categoryList,
        firstSelectedType,
        secondSelectedType,
        firstSelectedValue: 1,
        secondSelectedValue: 1,
    };
};

export const initialState: IConvertState = generateInitialState();
