import React from 'react';
import { useSelector } from 'react-redux';
import { getState } from './slice';
import { TooltipOverlay } from './tooltip-overlay';

export const TooltipContainer = () => {
    const { activeTooltips } = useSelector(getState);

    if (!activeTooltips?.length) return null;

    return (
        <>
            {activeTooltips.map((tooltip) => (
                <TooltipOverlay key={tooltip.id} {...tooltip} />
            ))}
        </>
    );
};
