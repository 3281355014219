export type ValidationType = 'default' | 'alphanumeric' | 'numeric' | 'decimal';

const numericRegexTyping = /(0|[1-9][\d]*)/g;
const numericRegexValidate = /^(0|[1-9][\d]*)$/;
const decimalRegexTyping = /(0|[1-9][\d]*)((,|\.)[\d]*)?/g;
const decimalRegexValidate = /^(0|[1-9][\d]*)((,|\.)[\d]+)?$/;

export const convertValueToType = (
    value: string,
    type: ValidationType
): string => {
    switch (type) {
        case 'default': {
            return value;
        }
        case 'alphanumeric': {
            return value;
        }
        case 'numeric': {
            const found = value.match(numericRegexTyping);

            return (
                found?.filter((item) => item !== '0').join('') ||
                found?.[0] ||
                '0'
            );
        }
        case 'decimal': {
            const found = value.match(decimalRegexTyping);

            found?.sort((a, b) => {
                if (a === '0') return 1;
                if (b === '0') return -1;

                return 0;
            });

            return found?.[0] || '0';
        }
        default: {
            throw new Error(`Unhandled validation type: ${type}`);
        }
    }
};

export const validateType = (value: string, type: ValidationType): boolean => {
    switch (type) {
        case 'default': {
            return true;
        }
        case 'alphanumeric': {
            return true;
        }
        case 'numeric': {
            return numericRegexValidate.test(value);
        }
        case 'decimal': {
            return decimalRegexValidate.test(value);
        }
        default: {
            throw new Error(`Unhandled validation type: ${type}`);
        }
    }
};
