import { IConvertState } from '../typings.convert';
import { convertUnitType } from 'utils/ingredient/convert';

type ConvertValueType = Pick<
    IConvertState,
    'firstSelectedValue' | 'secondSelectedValue'
>;

type ConvertSelectedType = Pick<
    IConvertState,
    'firstSelectedType' | 'secondSelectedType'
>;

export const convertAmount = (
    { firstSelectedValue, secondSelectedValue }: Partial<ConvertValueType>,
    { firstSelectedType, secondSelectedType }: ConvertSelectedType,
    selected: Partial<ConvertSelectedType> | null = null
): ConvertValueType => {
    if (
        firstSelectedValue !== undefined &&
        (selected === null || selected.firstSelectedType)
    ) {
        return {
            firstSelectedValue,
            secondSelectedValue: convertUnitType(
                firstSelectedValue,
                firstSelectedType,
                secondSelectedType
            ),
        };
    } else if (
        secondSelectedValue !== undefined &&
        (selected === null || selected.secondSelectedType)
    ) {
        return {
            firstSelectedValue: convertUnitType(
                secondSelectedValue,
                secondSelectedType,
                firstSelectedType
            ),
            secondSelectedValue,
        };
    }

    return {
        firstSelectedValue: 0,
        secondSelectedValue: 0,
    };
};
