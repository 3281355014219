const pluginMap: {
    // document types
    pdf: 'application/pdf';

    // media players
    qt: 'video/quicktime';
    realp: 'audio/x-pn-realaudio-plugin';
    wma: 'application/x-mplayer2';

    // interactive multimedia
    dir: 'application/x-director';
    fla: 'application/x-shockwave-flash';

    // RIA
    java: 'application/x-java-vm';
    gears: 'application/x-googlegears';
    ag: 'application/x-silverlight';
} = {
    // document types
    pdf: 'application/pdf',

    // media players
    qt: 'video/quicktime',
    realp: 'audio/x-pn-realaudio-plugin',
    wma: 'application/x-mplayer2',

    // interactive multimedia
    dir: 'application/x-director',
    fla: 'application/x-shockwave-flash',

    // RIA
    java: 'application/x-java-vm',
    gears: 'application/x-googlegears',
    ag: 'application/x-silverlight',
};

type BrowserFeaturesType = {
    res: string | null;
    cookie: boolean;
    plugins: string[];
} & Record<keyof typeof pluginMap, boolean>;

const browserFeatures = getBrowserFeatures();

export function getReferrer(): string {
    return (
        window?.top?.document?.referrer ||
        window?.parent?.document?.referrer ||
        document?.referrer ||
        ''
    );
}

export function generateRandomUuid(): string {
    return sha1(
        (navigator.userAgent || '') +
            (navigator.platform || '') +
            JSON.stringify(browserFeatures) +
            new Date().getTime() +
            Math.random()
    ).slice(0, 16);
}

export function generateBrowserSpecificId(): string {
    return sha1(
        (navigator.userAgent || '') +
            (navigator.platform || '') +
            JSON.stringify(browserFeatures)
    ).slice(0, 6);
}

export function getCurrentTimestampInSeconds(): number {
    return Math.floor(new Date().getTime() / 1000);
}

type PluginType = {
    name: string;
    mime: string;
    filename: string;
    description: string;
};

const getBrowserPlugins = function (): PluginType[] {
    const rdls = function (vals: PluginType[]): PluginType[] {
        const res = [];
		const tmp = vals.sort();
		
        for (let i = 0; i < tmp.length; i++) {
            res.push(tmp[i]);

            while (JSON.stringify(tmp[i]) == JSON.stringify(tmp[i + 1])) {
                i++;
            }
        }

        return res;
    };

    const res: PluginType[] = [];

    if (!navigator || !navigator.plugins) {
        return res;
    }

    for (const p in navigator.plugins) {
        const plugin = navigator.plugins[p];

        for (let j = 0; j < plugin.length; j++) {
            const mime = plugin[j];

            if (!mime) {
                continue;
            }

            const ep = mime.enabledPlugin;

            if (ep) {
                const item: PluginType = {
                    mime: mime.type,
                    name: ep.name,
                    description: ep.description,
                    filename: ep.filename,
                };

                res.push(item);
            }
        }
    }

    return rdls(res);
};

console.log(JSON.stringify(getBrowserPlugins()));

function getBrowserFeatures(): BrowserFeaturesType {
    const browserFeatures: Partial<BrowserFeaturesType> = { plugins: [] };

    // detect browser features except IE < 11 (IE 11 user agent is no longer MSIE)
    if (!new RegExp('MSIE').test(navigator.userAgent)) {
        // general plugin detection
        if (navigator.mimeTypes && navigator.mimeTypes.length) {
            let mimeType;

            for (const pluginKey in pluginMap) {
                if (
                    Object.prototype.hasOwnProperty.call(pluginMap, pluginKey)
                ) {
                    // @ts-ignore
                    mimeType = navigator.mimeTypes[pluginMap[pluginKey]];
                    // @ts-ignore
                    browserFeatures[pluginKey] =
                        mimeType && mimeType.enabledPlugin ? true : false;
                }
            }
        }

        // Safari and Opera
        // IE6/IE7 navigator.javaEnabled can't be aliased, so test directly
        // on Edge navigator.javaEnabled() always returns `true`, so ignore it
        if (
            !new RegExp('Edge[ /](\\d+[\\.\\d]+)').test(navigator.userAgent) &&
            (typeof navigator as any).javaEnabled !== 'unknown' &&
            typeof navigator.javaEnabled !== 'undefined' &&
            navigator.javaEnabled()
        ) {
            browserFeatures.java = true;
        }

        // Firefox
        if ((typeof window as any).GearsFactory === 'function') {
            browserFeatures.gears = true;
        }

        if (
            (typeof window as any).showModalDialog === 'undefined' &&
            typeof navigator.cookieEnabled !== 'undefined'
        ) {
            browserFeatures.cookie = navigator.cookieEnabled;
        } else {
            // Eg IE11 ... prevent error when cookieEnabled is requested within modal dialog. see #11507
            browserFeatures.cookie = true; //hasCookies(); // 🤷‍♀️
        }
    }

    //browserFeatures.res = `${screen.width}x${screen.height}`;

    // @ts-ignore
    /*for (plugin of navigator.plugins) {
		// @ts-ignore
		console.log(plugin.name);
		// @ts-ignore
        browserFeatures.plugins.push(plugin.name);
    }*/

    return browserFeatures as BrowserFeaturesType;
}

function sha1(str: string): string {
    // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
    // + namespaced by: Michael White (http://getsprink.com)
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   jslinted by: Anthon Pang (http://piwik.org)

    const rotateLeft = function (n: number, s: number): number {
        return (n << s) | (n >>> (32 - s));
    };

    const cvtHex = function (val: number): string {
        let strout = '';

        for (let i = 7; i >= 0; i--) {
            const v = (val >>> (i * 4)) & 0x0f;

            strout += v.toString(16);
        }

        return strout;
    };

    let H0 = 0x67452301,
        H1 = 0xefcdab89,
        H2 = 0x98badcfe,
        H3 = 0x10325476,
        H4 = 0xc3d2e1f0,
        A,
        B,
        C,
        D,
        E;

    const wordArray = [];

    str = utf8_encode(str);

    const stringLength = str.length;

    for (let i = 0; i < stringLength - 3; i += 4) {
        wordArray.push(
            (str.charCodeAt(i) << 24) |
                (str.charCodeAt(i + 1) << 16) |
                (str.charCodeAt(i + 2) << 8) |
                str.charCodeAt(i + 3)
        );
    }

    switch (stringLength & 3) {
        case 0:
            wordArray.push(0x080000000);
            break;
        case 1:
            wordArray.push(
                (str.charCodeAt(stringLength - 1) << 24) | 0x0800000
            );
            break;
        case 2:
            wordArray.push(
                (str.charCodeAt(stringLength - 2) << 24) |
                    (str.charCodeAt(stringLength - 1) << 16) |
                    0x08000
            );
            break;
        case 3:
            wordArray.push(
                (str.charCodeAt(stringLength - 3) << 24) |
                    (str.charCodeAt(stringLength - 2) << 16) |
                    (str.charCodeAt(stringLength - 1) << 8) |
                    0x80
            );
            break;
    }

    while ((wordArray.length & 15) !== 14) {
        wordArray.push(0);
    }

    wordArray.push(stringLength >>> 29);
    wordArray.push((stringLength << 3) & 0x0ffffffff);

    const W: number[] = [];

    for (let blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
        for (let i = 0; i < 16; i++) {
            W[i] = wordArray[blockstart + i];
        }

        for (let i = 16; i <= 79; i++) {
            W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
        }

        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;

        for (let i = 0; i <= 19; i++) {
            const temp: number =
                (rotateLeft(A, 5) +
                    ((B & C) | (~B & D)) +
                    E +
                    W[i] +
                    0x5a827999) &
                0x0ffffffff;

            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (let i = 20; i <= 39; i++) {
            const temp: number =
                (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) &
                0x0ffffffff;

            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (let i = 40; i <= 59; i++) {
            const temp: number =
                (rotateLeft(A, 5) +
                    ((B & C) | (B & D) | (C & D)) +
                    E +
                    W[i] +
                    0x8f1bbcdc) &
                0x0ffffffff;

            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (let i = 60; i <= 79; i++) {
            const temp: number =
                (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) &
                0x0ffffffff;

            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
    }

    const temp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);

    return temp.toLowerCase();
}

function utf8_encode(argString: string): string {
    return unescape(window.encodeURIComponent(argString));
}
