import { postData, Response } from '../post';

export function error(error: Error): Promise<Response<never>> {
    return postData<never>({
        url: 'track/error',
        data: {
            errorMessage: error.toString(),
            stack: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        },
    });
}
