import React from 'react';
import { IconKey } from './types';
import { icons } from './icons';
import style from './icon.module.scss';
import { classNames } from 'utils';

interface IIcon extends Partial<HTMLElement> {
    icon: IconKey;
}

export const Icon = ({ icon, className }: IIcon) => {
    const [width, height, path] = icons[icon] as [number, number, string];
    const iconClassName = classNames(style.icon, className);

    return (
        <svg
            className={iconClassName}
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${width} ${height}`}
        >
            <path fill="currentColor" d={path}></path>
        </svg>
    );
};
