import React, { FunctionComponent } from 'react';
import { IChildren, IClassName } from 'typings';
import { classNames } from 'utils';
import styles from './badge.module.scss';
import { Tooltip } from 'components/overlays/tooltip';

export interface IBadge extends IChildren, IClassName {
    color?: 'primary' | 'default';
    type?: 'round' | 'pill' | 'rect' | 'square';
    title?: string;
}

export const Badge: FunctionComponent<IBadge> = ({
    children,
    color = 'default',
    type = 'round',
    title,
    className,
}: IBadge) => {
    const badgeClassName = classNames(
        styles.badge,
        styles[type],
        styles[color],
        className
    );

    const badge = <div className={badgeClassName}>{children}</div>;

    if (title) {
        return <Tooltip title={title}>{badge}</Tooltip>;
    }

    return badge;
};
