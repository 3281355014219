import { IFridgeReducer, IFridgeState } from './typings.fridge';

export const reducer: IFridgeReducer = (state, action): IFridgeState => {
    switch (action.type) {
        case 'setRecipes': {
            return {
                ...state,
                recipes: action.payload,
                isLoading: false,
            };
        }
        case 'startLoading': {
            return { ...state, isLoading: true };
        }
        case 'includeIngredient': {
            if (state.includedIngredients.includes(action.payload))
                return state;

            return {
                ...state,
                includedIngredients: [
                    ...state.includedIngredients,
                    action.payload,
                ],
            };
        }
        case 'excludeIngredient': {
            if (state.excludedIngredients.includes(action.payload))
                return state;

            return {
                ...state,
                excludedIngredients: [
                    ...state.excludedIngredients,
                    action.payload,
                ],
            };
        }
        case 'toggleIngredient': {
            return state; // TODO
        }
        case 'removeIngredient': {
            return {
                ...state,
                includedIngredients: state.includedIngredients.filter(
                    (ingredient) => ingredient.id !== action.payload
                ),
                excludedIngredients: state.excludedIngredients.filter(
                    (ingredient) => ingredient.id !== action.payload
                ),
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};
