import { getRandomNumber } from './number';

export const strings = [
    'Run at 3am bite nose of your human you are a captive audience while sitting on the toilet, pet me a nice warm laptop for me to sit on or bird bird bird bird bird bird human why take bird out i could have eaten that yet i bet my nine lives on you-oooo-ooo-hooo, or meow to be let out.',
    "Hide at bottom of staircase to trip human cat snacks, or put butt in owner's face and i shredded your linens for you.",
    'Stare out cat door then go back inside scratch my tummy actually i hate you now fight me soft kitty warm kitty little ball of furr.',
    "Pee in the shoe i want to go outside let me go outside nevermind inside is better stare at ceiling light, stretch out on bed, kitty run to human with blood on mouth from frenzied attack on poor innocent mouse, don't i look cute? if it fits, i sits.",
    'Have secret plans slap the dog because cats rule so cry louder at reflection knock dish off table head butt cant eat out of my own dish ptracy, yet i rule on my back you rub my tummy i bite you hard.',
    'Found somthing move i bite it tail jump five feet high and sideways when a shadow moves.',
    "Please stop looking at your phone and pet me sit in a box for hours so hack hiss at vacuum cleaner, or bite the neighbor's bratty kid but kitten is playing with dead mouse commence midnight zoomies.",
    'I like big cats and i can not lie i will be pet i will be pet and then i will hiss.',
    'The fat cat sat on the mat bat away with paws.',
    'Chase dog then run away.',
    'Run outside as soon as door open making bread on the bathrobe.',
    "If it smells like fish eat as much as you wish bite off human's toes and always hungry yet good morning sunshine or cuddle no cuddle cuddle love scratch scratch yet scratch me there, elevator butt.",
    "Let me in let me out let me in let me out let me in let me out who broke this door anyway sleep on my human's head attack feet ears back wide eyed for chew foot chase ball of string drink from the toilet.",
    'Meow loudly just to annoy owners throw down all the stuff in the kitchen, walk on keyboard.',
    "Swat turds around the house meowwww i'm going to lap some water out of my master's cup meow.",
    'Rub face on everything.',
    "Eat from dog's food.",
    'Oooo! dangly balls! jump swat swing flies so sweetly to the floor crash move on wash belly nap toilet paper attack claws fluff everywhere meow miao french ciao litterbox for thug cat .',
    'Purr when being pet please stop looking at your phone and pet me hey! you there, with the hands.',
    'Purrr purr littel cat, little cat purr purr ooooh feather moving feather!, yet burrow under covers, so sit in a box for hours reward the chosen human with a slow blink.',
    'Purr then cats take over the world.',
];

export const getRandomString = (maxLength?: number): string => {
    const length = getRandomNumber(0, strings.length - 1);
    const chosenString = strings[length];

    if (!maxLength || chosenString.length <= maxLength) return chosenString;

    const wordArray = chosenString.substr(0, maxLength + 1).split(' ');

    wordArray.pop();

    return wordArray.join(' ');
};

export const getRandomKey = (length: number): string => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let result = '';

    for (let index = 0; index < length; index++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
};
