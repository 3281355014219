import React, { ReactNode } from 'react';
import style from './numbered-list.module.scss';
import { classNames } from 'utils';

interface INumberedList extends Partial<HTMLOListElement> {
    list: ReactNode[];
}

export const NumberedList = ({ className, list }: INumberedList) => {
    const listClassName = classNames(style.list, className);

    return (
        <ol className={listClassName}>
            {list.map((item, index) => (
                <li key={index} className={style.item}>
                    {item}
                </li>
            ))}
        </ol>
    );
};
