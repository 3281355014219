import React, { FunctionComponent } from 'react';
import styles from './removeable-badge.module.scss';
import { Tooltip } from 'components/overlays/tooltip';
import { Icon } from 'components/images-icons/icon';
import { Badge, IBadge } from 'components/titles-text/badge';

interface IRemoveableBadge extends Omit<IBadge, 'type'> {
    onClick: () => void;
    asPartOfList?: boolean;
}

export const RemoveableBadge: FunctionComponent<IRemoveableBadge> = ({
    children,
    title,
    onClick,
    asPartOfList,
}: IRemoveableBadge) => {
    const className = [styles.badge, asPartOfList && styles.partOfList]
        .filter(Boolean)
        .join(' ');

    return (
        <Badge type="rect" title={title} className={className} color="default">
            <div className={styles.text}>{children}</div>
            <Tooltip className={styles.buttonTooltip} title="Fjern">
                <button className={styles.button} onClick={onClick}>
                    <Icon icon="times" />
                </button>
            </Tooltip>
        </Badge>
    );
};
