import * as recipe from './recipe';
import * as ingredient from './ingredient';
import * as search from './search';
import * as track from './track';
import * as media from './media';

export const api = {
    recipe,
    ingredient,
    search,
    track,
    media,
};
