import { IPreviewRecipe } from '../typings';
import { getSrc } from 'api/media';

export function mapMediaSrc<T extends IPreviewRecipe>(recipe: T): T {
    if (!recipe.mediaSrc && recipe.mediaID) {
        recipe.mediaSrc = getSrc(recipe.mediaID);
    }

    return recipe;
}

export function mapMultipleMediaSrc<T extends IPreviewRecipe>(
    recipes: T[]
): T[] {
    return recipes.map(mapMediaSrc);
}
