import {
    IAutocompleteState,
    AutocompleteOptionsType,
} from './typings.autocomplete';

export const initializer = (options?: AutocompleteOptionsType) => (
    state: IAutocompleteState
): IAutocompleteState => ({
    ...state,
    isLoading: state.style === 'preload',
    isInitiated: state.style === 'preload',
    markType: options?.markType ?? 'none',
    clearOnSelect: options?.clearOnSelect ?? false,
});
