import {
    IAutocompleteItem,
    IAutocompleteDispatch,
} from './typings.autocomplete';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const actions = (dispatch: IAutocompleteDispatch) => ({
    setValue: (payload: string): void =>
        dispatch({ type: 'setValue', payload }),
    setCurrentActiveItem: (payload: number | null): void =>
        dispatch({
            type: 'setCurrentActiveItem',
            payload,
        }),
    moveActiveItemUp: (): void => dispatch({ type: 'moveActiveItemUp' }),
    moveActiveItemDown: (): void => dispatch({ type: 'moveActiveItemDown' }),
    setFocus: (): void => dispatch({ type: 'setFocus' }),
    setBlur: (): void => dispatch({ type: 'setBlur' }),
    setList: (payload: IAutocompleteItem[]): void =>
        dispatch({ type: 'setList', payload }),
    updateDisplayList: (): void => dispatch({ type: 'updateDisplayList' }),
});
