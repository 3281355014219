import { useEffect, useReducer, useMemo } from 'react';
import { api } from 'api';
import { actions } from './actions.fridge';
import { IFridgeState } from './typings.fridge';
import { reducer } from './reducer.fridge';
import { initialState } from './initial-state.fridge';

export const useFridgeState = (): {
    state: IFridgeState;
    actions: ReturnType<typeof actions>;
} => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchedActions = useMemo(() => actions(dispatch), [dispatch]);

    useEffect(() => {
        if (
            !state.includedIngredients.length &&
            !state.excludedIngredients.length
        ) {
            dispatchedActions.setRecipes([]);

            return;
        }

        api.recipe
            .search({
                includeIngredients:
                    state.includedIngredients.map(
                        (ingredient) => ingredient.id
                    ) || [],
                /*excludeIngredients: state.excludedIngredients.map(
                    (ingredient) => ingredient.id
                ) || [],*/
            })
            .then((response) => {
                if (response.success) {
                    dispatchedActions.setRecipes(response.result);
                }
            });
    }, [state.includedIngredients, state.excludedIngredients]);

    return { state, actions: dispatchedActions };
};
