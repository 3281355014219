interface IDebounce {
    (callback: (...args: any[]) => void, delay?: number): (
        ...args: any[]
    ) => void;
}

export const debounce: IDebounce = (callback, delay = 400) => {
    let inDebounce: NodeJS.Timeout;

    return (...args: any[]): void => {
        clearTimeout(inDebounce);

        inDebounce = setTimeout(() => callback(...args), delay);
    };
};
