import { getScrollPositionOfElement } from './get-scroll-position';
import { IScrollRect } from './typings';

export function getContent(): HTMLElement {
    const content = document.body.querySelector('#content');

    if (!content) {
        throw Error('No content');
    }

    return content as HTMLElement;
}

export function getContentRect(): IScrollRect {
    const content = getContent();

    const { top, left, width, height } = content.getBoundingClientRect();

    const { top: scrollTop, left: scrollLeft } = getScrollPositionOfElement(
        content
    );

    return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
        scrollTop: Math.round(scrollTop),
        scrollLeft: Math.round(scrollLeft),
    };
}
