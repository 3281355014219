import { IPublicRecipe } from './typings';
import { postData, Response } from '../post';
import { mapMediaSrc } from './utils';

export function get(uri: string): Promise<Response<IPublicRecipe>> {
    return postData<IPublicRecipe>({
        url: 'recipe/get',
        data: uri,
        formatResult: mapMediaSrc,
    });
}
