import React from 'react';
import { H1, H2, H3, H4, Bolder, Spacer } from 'utils/formatting';

export const Cookies = () => {
    return (
        <>
            <H1 center>Cookiepolitik</H1>
            <H4 center>
                Cookies anvendes til at <Bolder>analysere webtrafikken</Bolder>{' '}
                samt til <Bolder>nødvendige funktioner</Bolder> på hjemmesiden.
            </H4>
            <Spacer />
            <H2>Cookies</H2>
            <p>
                Cookies er små tekstfiler, som kan bruges af hjemmesider til at
                forbedre brugeroplevelsen. Der er ikke gemt personlige
                oplysninger i vores cookies, og de kan ikke indeholde virus.
            </p>
            <p>
                Herunder ses en liste over formålene Spis Billigt har til at
                anvende cookies:
            </p>
            <ul>
                <li>
                    <Bolder>Teknisk funktionalitet:</Bolder> Til at huske om du
                    er logget ind eller ej.
                </li>
                <li>
                    <Bolder>Trafikmåling*:</Bolder> Til at vide hvor mange der
                    besøger hjemmesiden og for at se hvilket indhold der er
                    populært.
                </li>
                <li>
                    <Bolder>Optimering:</Bolder> Til at forbedre hjemmesidens
                    funktionaliteter og udseende.
                </li>
            </ul>
            * Deles med tredjepart (Google Analytics).
            <Spacer />
            <H3>Google Analytics</H3>
            <p>
                Hjemmesiden anvender nogle cookies til at kunne gemme dine
                sidevisninger fra Spis Billigt og sende dem til Google Analytics
                så de kan medgå i analysen over besøg på hjemmesiden. Dette
                gøres for at holde øje med hvilke sider der er de mest populære
                og bruge den viden når der skal udvikles nye sider. Det eneste
                cookien gemmer er et unikt genereret ID så Google Analytics kan
                genkende dig over alle de besøgende sider på Spis Billigt. Dette
                ID deles kun med Google Analytics og indeholder ingen
                persondata.
            </p>
            <p>
                <Bolder>
                    Alt data delt med Google Analytics er anonymiseret.
                </Bolder>
            </p>
            <Spacer />
            <H3>Nødvendig cookies</H3>
            <p>
                Nødvendige cookies hjælper med at gøre en hjemmeside brugbar ved
                at aktivere grundlæggende funktioner såsom side-navigation og
                adgang til sikre områder af hjemmesiden. Hjemmesiden kan ikke
                fungere ordentligt uden disse cookies.
            </p>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Udbyder</th>
                            <th>Formål</th>
                            <th>Udløb</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>ci_session</p>
                            </td>
                            <td>
                                <p>spisbilligt.dk</p>
                            </td>
                            <td>
                                <p>
                                    Bevarer brugertilstand på tværs af
                                    sideforespørgsler.
                                </p>
                            </td>
                            <td>
                                <p>Session</p>
                            </td>
                            <td>
                                <p>
                                    HTTP
                                    <br />
                                    Cookie
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>sb_session</p>
                            </td>
                            <td>
                                <p>spisbilligt.dk</p>
                            </td>
                            <td>
                                <p>
                                    Bevarer brugertilstand på tværs af
                                    sideforespørgsler når &quot;husk mig&quot;
                                    funktionen er tilvalgt ved logind.
                                </p>
                            </td>
                            <td>
                                <p className="nowrap">30 dage</p>
                            </td>
                            <td>
                                <p>
                                    HTTP
                                    <br />
                                    Cookie
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>sb_auth</p>
                            </td>
                            <td>
                                <p>spisbilligt.dk</p>
                            </td>
                            <td>
                                <p>
                                    Bevarer brugerautentificering på tværs af
                                    sideforespørgsler når en bruger har tilvalgt
                                    to-trin godkendelse.
                                </p>
                            </td>
                            <td>
                                <p className="nowrap">30 dage</p>
                            </td>
                            <td>
                                <p>
                                    HTTP
                                    <br />
                                    Cookie
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>sb_settings</p>
                            </td>
                            <td>
                                <p>spisbilligt.dk</p>
                            </td>
                            <td>
                                <p>
                                    Gemmer brugerens cookie- og
                                    analyse-samtykke.
                                </p>
                            </td>
                            <td>
                                <p>1 år</p>
                            </td>
                            <td>
                                <p>
                                    HTTP
                                    <br />
                                    Cookie
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Spacer />
            <H3>Analyse cookies</H3>
            <p>
                Analyse cookies hjælper webstedsejere med at forstå, hvordan de
                besøgende interagerer med hjemmesider ved at indsamle og
                rapportere oplysninger anonymt.
            </p>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Udbyder</th>
                            <th>Formål</th>
                            <th>Udløb</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>ga_session</p>
                            </td>
                            <td>
                                <p>spisbilligt.dk</p>
                            </td>
                            <td>
                                <p>
                                    Registrerer et unikt ID, der anvendes til at
                                    føre analyse over hvordan den besøgende
                                    bruger hjemmesiden.
                                </p>
                            </td>
                            <td>
                                <p>1 år</p>
                            </td>
                            <td>
                                <p>
                                    HTTP
                                    <br />
                                    Cookie
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Spacer />
            <p>
                <i>Sidst opdateret den 6. juni 2020.</i>
            </p>
        </>
    );
};
