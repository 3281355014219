import React, { useReducer, useEffect } from 'react';
import { IChildren } from 'typings';
import { initialState } from './initial-state';
import { reducer } from './reducer';
import { StateContext, DispatchContext } from './contexts';
import { getPath } from 'utils/path/get-path';
import { usePath } from 'hooks';

export function Provider({ children }: IChildren) {
    const path = usePath();

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        path: getPath(),
    });

    useEffect(() => {
        dispatch({ type: 'setPath', payload: path });
    }, [path]);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
}
