import React, { useEffect } from 'react';
import { IPreviewRecipe } from 'api/recipe';
import styles from './preview-recipe.module.scss';
import { Link } from 'utils';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'hooks/use-in-view';

export const PreviewRecipe = ({ title, mediaSrc, uri }: IPreviewRecipe) => {
    const controls = useAnimation();
    const { ref, inView, preload } = useInView({
        listenOnExit: false,
        usePreload: !!mediaSrc,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
            }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className={styles.container}
        >
            <Link key={uri} to={`/opskrift/${uri}`} className={styles.link}>
                {mediaSrc && preload && (
                    <img alt={title} className={styles.image} src={mediaSrc} />
                )}
                <div className={styles.overlay} />
                <div className={styles.title}>{title}</div>
            </Link>
        </motion.div>
    );
};
