import React, { FunctionComponent } from 'react';
import { Provider } from './context';
import { Container } from './components';

export const Navigation: FunctionComponent = () => {
    return (
        <Provider>
            <Container />
        </Provider>
    );
};
