import React, { useEffect, useRef } from 'react';
import { Route, Switch, RouteProps, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IRouteProps } from 'typings';
import { Showroom } from './features/showroom';
import { Handler as RecipeHandler } from './features/recipe';
import { Recipes } from 'features/recipes';
import { Layout } from 'features/layout';
import { IngredientRoute } from 'features/ingredients';
import { clearAllTooltips } from 'components/overlays/tooltip/slice';
import { authenticate } from 'utils/authenticate';
import { Cookies } from 'features/misc/cookies';
import { Privacy } from 'features/misc/privacy';
import { getReferrer, generateBrowserSpecificId } from 'utils/track';

const getComponent = (controller: string) => {
    switch (controller) {
        case undefined: {
            return <div><h1 className="center">Velkommen til Spis Billigt!</h1></div>;
        }
        case 'opskrift': {
            return <RecipeHandler />;
        }
        case 'opskrifter': {
            return <Recipes />;
        }
        case 'ingredienser': {
            return <IngredientRoute />;
        }
        case 'cookiepolitik': {
            return <Cookies />;
        }
        case 'privatlivspolitik': {
            return <Privacy />;
        }
        default: {
            return <>Indhold kommer...</>;
        }
    }
};

const Component = ({ location }: RouteProps) => {
    const referrer = useRef<string>();
    const pathname = location?.pathname || '';
    const paths = pathname.split('/').filter(Boolean);
    const controller = paths?.[0];

    const path: IRouteProps['path'] = {
        paths,
        pathname,
        controller,
    };

    const dispatch = useDispatch();

    //console.log(authenticate());
    //printNavTimingData();
    console.log(generateBrowserSpecificId());

    useEffect(() => {
        dispatch(clearAllTooltips());
        console.log(
            path.pathname,
            getReferrer(),
            referrer.current,
            referrer.current || getReferrer(),
            //printNavTimingData()
            { reloaded: window.location.href === referrer.current }
        );

        return () => {
            referrer.current = window.location.href;
        };
    }, [dispatch, path]);

    if (process.env.NODE_ENV === 'development' && controller === 'showroom') {
        return <Showroom path={path} />;
    }

    const component = getComponent(controller);

    return <Layout path={path}>{component}</Layout>;
};

function printNavTimingData() {
    // Use getEntriesByType() to just get the "navigation" events
    const perfEntries = performance.getEntriesByType("navigation");
  
    for (let i = 0; i < perfEntries.length; i++) {
        console.log("= Navigation entry[" + i + "]");
        const p = perfEntries[i];

        console.log(p);
      // @ts-ignore
      console.log(p.type, p.entryType, p.initiatorType);

      // dom Properties
      // @ts-ignore
      console.log("DOM content loaded = " + (p.domContentLoadedEventEnd - p.domContentLoadedEventStart));
      // @ts-ignore
      console.log("DOM complete = " + p.domComplete);
      // @ts-ignore
      console.log("DOM interactive = " + p.interactive);
  
      // document load and unload time
      // @ts-ignore
      console.log("document load = " + (p.loadEventEnd - p.loadEventStart));
      // @ts-ignore
      console.log("document unload = " + (p.unloadEventEnd - p.unloadEventStart));
  
      // other properties
      // @ts-ignore
      console.log("type = " + p.type);
      // @ts-ignore
      console.log("redirectCount = " + p.redirectCount);
    }
  }

export const Routes = () => (
    <Switch>
        <Route
            path="*"
            exact
            component={(props: any) => <Component {...props} />}
        />
    </Switch>
);
