import React, { FunctionComponent } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Tooltip, ITooltip } from 'components/overlays/tooltip';

type LinkProps = Parameters<typeof ReactLink>[0] &
    Partial<ITooltip> & { delayed?: boolean };

export const Link: FunctionComponent<LinkProps> = ({
    children,
    title,
    delayed = true,
    placement,
    ...props
}: LinkProps) => {
    if (title) {
        return (
            <Tooltip
                title={title}
                delay={delayed ? 1000 : undefined}
                placement={placement}
            >
                <ReactLink {...props} title="">
                    {children}
                </ReactLink>
            </Tooltip>
        );
    }

    return <ReactLink {...props}>{children}</ReactLink>;
};
