export const list = {
    design: ['colors'],
    forms: ['input-with-header', 'input', 'select'],
    'images-icons': ['profile-picture', 'logo', 'icon'],
    overlays: ['tooltip'],
    navigation: ['navigation'],
    'lists-tables': ['table', 'numbered-list', 'check-list'],
    actions: ['autocomplete', 'checkbox', 'button'],
    'feedback-indicators': ['spinner'],
};
