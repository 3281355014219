import { IPreviewRecipe } from './typings';
import { postData, Response } from '../post';
import { mapMultipleMediaSrc } from './utils';

export function picture(): Promise<Response<IPreviewRecipe[]>> {
    return postData<IPreviewRecipe[]>({
        url: 'recipe/picture',
        formatResult: mapMultipleMediaSrc,
    });
}
