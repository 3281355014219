import React from 'react';
import { classNames } from 'utils';
import styles from '../navigation.module.scss';
import { IRouteType } from '../types';
import { Link } from './link';

export const Content = (route: IRouteType & { activeRouteName: string }) => {
    const visible = route.activeRouteName === route.name;

    const className = classNames(styles.content, {
        [styles.visible]: visible,
    });

    return (
        <ul className={className}>
            {route.list.map((item) => (
                <Link
                    key={item.path}
                    name={item.name}
                    description={item.description}
                    activeCondition={item.activeCondition}
                    path={item.path}
                />
            ))}
        </ul>
    );
};
