import React, { Fragment } from 'react';
import { Link } from 'utils';
import { Icon } from 'components/images-icons/icon';
import styles from './breadcrumb.module.scss';

type IBreadcrumbItem = {
    title: string;
    link: string;
};

interface IBreadcrumb {
    base: IBreadcrumbItem;
    list: IBreadcrumbItem[];
}

export const Breadcrumb = ({ base, list }: IBreadcrumb) => {
    const length = list.length;

    if (!length) return null;

    const breadcrumbs = [base, ...list];

    return (
        <div className={styles.container}>
            {breadcrumbs.map(({ link, title }, index) => (
                <Fragment key={index}>
                    {index < length ? (
                        <>
                            <Link
                                to={index === 0 ? link : `${base.link}${link}`}
                                title={`Gå til ${title}`}
                            >
                                {title}
                            </Link>
                            <Icon icon="angle-right" />
                        </>
                    ) : (
                        title
                    )}
                </Fragment>
            ))}
        </div>
    );
};
