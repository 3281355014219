import { cookieExists } from 'utils/cookie';
import { LocalStorageTypes } from './typings';
import { jsonTryParse } from 'utils/json';

export const canUseLocalStorage = (): boolean => {
    return false && cookieExists('useLocalStorage');
};

export const getFromLocalStorage = <T>(
    stateScope: LocalStorageTypes,
    defaultValue: T
): T => {
    return (canUseLocalStorage()
        ? jsonTryParse<T>(localStorage.getItem(stateScope)) ?? defaultValue
        : defaultValue) as T;
};

export const setFromLocalStorage = <T>(
    stateScope: LocalStorageTypes,
    value: T
): void => {
    localStorage.setItem(stateScope, JSON.stringify(value));
};
