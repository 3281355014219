import React, { ReactNode } from 'react';
import style from './check-list.module.scss';
import { Checkbox } from 'components/actions/checkbox';

interface ICheckedList {
    list: ReactNode[];
}

export const CheckedList = ({ list }: ICheckedList) => {
    return (
        <>
            {list.map((item, index) => (
                <Checkbox key={index} className={style.checkbox} label={item} />
            ))}
        </>
    );
};
