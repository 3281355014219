import React, { useContext } from 'react';
import { classNames, matchTestString } from 'utils';
import styles from '../navigation.module.scss';
import { IRouteItemType } from '../types';
import { StateContext } from '../context';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
    name,
    description,
    activeCondition,
    path,
}: IRouteItemType) => {
    const {
        path: { pathname },
    } = useContext(StateContext);

    const active = matchTestString(pathname, activeCondition);

    const className = classNames(styles.item, {
        [styles.active]: active,
    });

    return (
        <li key={name} className={className}>
            <RouterLink to={`/${path}`}>
                <div className={styles.itemName}>{name}</div>
                <div className={styles.itemDescription}>{description}</div>
            </RouterLink>
        </li>
    );
};
