import React, { useEffect, useState } from 'react';
import { api } from 'api';
import { IPreviewRecipe } from 'api/recipe';
import { PreviewRecipe } from 'components/structure/preview-recipe';
import { usePath } from 'hooks';
import { AlphabeticalList } from 'components/lists-tables/alphabetical-list';

export function Recipes() {
    const path = usePath();

    const [recipes, setRecipes] = useState<IPreviewRecipe[]>([]);
    const type = path.paths?.length <= 2 && path.paths?.[1];

    useEffect(() => {
        if (type && type !== 'billede') return;

        const load = async () => {
            const loadedRecipes = await (type
                ? api.recipe.picture()
                : api.recipe.all());

            if (loadedRecipes.success) {
                setRecipes(loadedRecipes.result);
            }
        };

        load();
    }, [type]);

    if (!recipes?.length) {
        return <>Loader?</>;
    }

    return (
        <>
            <h1 className="center">
                {type === 'billede'
                    ? 'Alle opskrifter med billede'
                    : 'Alle opskrifter'}
            </h1>
            <p className="center">
                Her finder du en oversigt over alle opskrifter
            </p>
            <AlphabeticalList
                list={recipes.map((recipe) => ({
                    title: recipe.title,
                    component: <PreviewRecipe key={recipe.uri} {...recipe} />,
                }))}
            />
        </>
    );
}
