import { useReducer, useMemo } from 'react';
import { reducer } from './reducer.convert';
import { initialState } from './initial-state.convert';
import { actions } from './actions.convert';
import { IConvertState } from './typings.convert';

export const useConvertState = (): {
    state: IConvertState;
    actions: ReturnType<typeof actions>;
} => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchedActions = useMemo(() => actions(dispatch), [dispatch]);

    return {
        state,
        actions: dispatchedActions,
    };
};
