import { IIngredientList } from './typings';
import { postData, Response } from '../post';
import { mapMediaSrc } from 'api/recipe/utils';

export function recipes(uri?: string): Promise<Response<IIngredientList>> {
    return postData<IIngredientList>({
        url: 'ingredient/recipes',
        data: uri,
        formatResult: (ingredientList) => {
            if (ingredientList.recipes?.length) {
                ingredientList.recipes = ingredientList.recipes.map(
                    mapMediaSrc
                );
            }

            return ingredientList;
        },
    });
}
