import React, { useState, useEffect } from 'react';
import { throttle } from 'utils';
import { Button } from 'components/actions/button';
import styles from './alphabetical-list.module.scss';
import { CategorizedListItem } from './typings';

interface ICategoryList {
    categoryKeys: string[];
    categories: CategorizedListItem[];
    onClick: (index: number) => void;
    getCurrentCategory: () => string;
}

export const CategoryList = ({
    categoryKeys,
    categories,
    getCurrentCategory,
    onClick,
}: ICategoryList) => {
    const [currentActiveKey, setCurrentActiveKey] = useState<string>(
        categoryKeys[0]
    );

    const handleScroll = throttle(() => {
        const currentKey = getCurrentCategory();

        if (currentKey) {
            setCurrentActiveKey(currentKey);
        }
    }, 100);

    useEffect(() => {
        const content = document.body.querySelector('#content');

        if (content) {
            content.addEventListener('scroll', handleScroll, false);

            return () =>
                content.removeEventListener('scroll', handleScroll, false);
        }
    }, [categories]);

    const handleClick = (value: string) => {
        const index = categoryKeys.findIndex((key) => key === value);

        if (index === -1) return;

        onClick(index);
    };

    return (
        <div className={styles.categories}>
            {categoryKeys.map((category) => (
                <Button
                    key={category}
                    type={category === currentActiveKey ? 'primary' : 'dark'}
                    className={styles.button}
                    onClick={handleClick}
                    value={category}
                >
                    {category}
                </Button>
            ))}
        </div>
    );
};
