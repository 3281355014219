import React, { FunctionComponent } from 'react';
import { IIngredient } from 'api/ingredient/typings';
import { RemoveableBadge } from 'components/titles-text/removeable-badge';
import { Autocomplete, OnChangeType } from 'components/actions/autocomplete';
import { useFridgeState } from './use-fridge-state';
import { PreviewRecipe } from 'components/structure/preview-recipe';
import { search } from './actions';
import { Spinner } from 'components/feedback-indicators/spinner';
import styles from './fridge.module.scss';
import { Tooltip } from 'components/overlays/tooltip';

export const Fridge: FunctionComponent = () => {
    const {
        state: { includedIngredients, recipes, isLoading },
        actions: { includeIngredient, removeIngredient },
    } = useFridgeState();

    const handleChange: OnChangeType<{ ingredient: IIngredient }> = (item) => {
        if (item?.ingredient) {
            includeIngredient(item.ingredient);
        }
    };

    return (
        <>
            <h1 className="center">Tøm køleskabet</h1>
            <p className="center">
                Tilføj let ingredienser du til din søgning og bliv inspireret
                til at få varene brugt.
            </p>
            <Autocomplete
                label="Søg efter ingrediens"
                //placeholder="Find ingrediens"
                search={search}
                style="load"
                options={{ clearOnSelect: true, markType: 'match' }}
                onChange={handleChange}
            />
            <div className={styles.ingredientList}>
                {includedIngredients.map((ingredient) => (
                    <RemoveableBadge
                        key={ingredient.id}
                        onClick={() => removeIngredient(ingredient.id)}
                        asPartOfList
                    >
                        <Tooltip
                            title={ingredient.breadcrumb
                                .map((breadcrumb) => breadcrumb.title)
                                .join(' – ')}
                            delay={1000}
                        >
                            {ingredient.title}
                        </Tooltip>
                    </RemoveableBadge>
                ))}
            </div>
            <div>
                {isLoading && <Spinner />}
                {recipes.map((recipe) => (
                    <PreviewRecipe
                        key={recipe.uri}
                        {...recipe}
                    />
                ))}
            </div>
        </>
    );
};
