import React, {
    useState,
    useEffect,
    useCallback,
    forwardRef,
    ChangeEvent,
} from 'react';
import { convertValueToType, ValidationType } from 'utils/validate';

interface INumberInput {
    value?: number;
    placeholder?: string;
    className?: string;
    name?: string;
    type: ValidationType;
    maxLength?: number;
    onChange?: (
        value: Record<string, number>,
        event: ChangeEvent<HTMLInputElement>
    ) => void;
}

const convertIntegerToLocale = (number: number): string =>
    number
        .toLocaleString('da-DK', { maximumFractionDigits: 0 })
        .replace(/\./g, '');

const convertDecimalToLocale = (number: number): string =>
    number
        .toLocaleString('da-DK', { maximumFractionDigits: 2 })
        .replace(/\./g, '');

const convertNumberFromLocale = (number: string): number =>
    number ? parseFloat(number.replace(/\./g, '').replace(/,/g, '.')) : 0;

export const NumberInput = forwardRef<HTMLInputElement, INumberInput>(
    (
        {
            value,
            placeholder,
            onChange,
            name = 'value',
            type,
            maxLength,
            className,
        }: INumberInput,
        ref
    ) => {
        const [state, setState] = useState<string>(value?.toString() || '');

        useEffect(() => {
            if (typeof value === 'number') {
                const number = convertNumberFromLocale(state);

                if (number !== value) {
                    setState(convertDecimalToLocale(value));
                }
            }
        }, [value, type, state]);

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                const value = convertValueToType(event.target.value, type);

                setState(value);

                const number = convertNumberFromLocale(value);

                return onChange?.({ [name]: number }, event);
            },
            [onChange, name, type]
        );

        return (
            <input
                ref={ref}
                value={state}
                placeholder={placeholder}
                onChange={handleChange}
                className={className}
                maxLength={maxLength}
            />
        );
    }
);
