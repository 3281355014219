import { useReducer, useMemo } from 'react';
import { reducer } from './reducer.autocomplete';
import { initialState } from './initial-state.autocomplete';
import { actions } from './actions.autocomplete';
import {
    IAutocompleteState,
    AutocompleteOptionsType,
} from './typings.autocomplete';
import { initializer } from './initializer.autocomplete';

interface IUseAutocompleteState
    extends Partial<Pick<IAutocompleteState, 'style'>> {
    options?: AutocompleteOptionsType;
}

export const useAutocompleteState = ({
    style = 'search',
    options,
}: IUseAutocompleteState): {
    state: IAutocompleteState;
    actions: ReturnType<typeof actions>;
} => {
    const [state, dispatch] = useReducer(
        reducer,
        {
            ...initialState,
            style,
        },
        initializer(options)
    );

    const dispatchedActions = useMemo(() => actions(dispatch), [dispatch]);

    return {
        state,
        actions: dispatchedActions,
    };
};
