import { getIntArray } from './get-int-array';
import { ISortByOptions } from './typings';

export const handleOrdering = <T>(
    a: T,
    b: T,
    options?: ISortByOptions
): number => {
    const nullMultiplier = options?.order === 'descendingNullLast' ? -1 : 1;

    if (a === null || b === null) {
        if (a === null && b === null) return 0;
        else return (a === null ? 1 : -1) * nullMultiplier;
    }

    if (
        (typeof a === 'number' && Number.isNaN(a)) ||
        (typeof b === 'number' && Number.isNaN(b))
    ) {
        if (
            typeof a === 'number' &&
            Number.isNaN(a) &&
            typeof b === 'number' &&
            Number.isNaN(b)
        )
            return 0;
        else
            return (
                (typeof a === 'number' && Number.isNaN(a) ? 1 : -1) *
                nullMultiplier
            );
    }

    if (a instanceof Date && b instanceof Date) {
        return a.valueOf() - b.valueOf();
    } else if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    } else if (
        (typeof a === 'string' || typeof a === 'number') &&
        (typeof b === 'string' || typeof b === 'number')
    ) {
        const aArray = getIntArray(a.toString());
        const bArray = getIntArray(b.toString());
        const length = aArray.length;

        for (let index = 0; index < length; index++) {
            if (aArray[index] !== bArray[index]) {
                return aArray[index] - bArray[index];
            }
        }
    }

    return 0;
};

export const handleOptions = (
    result: number,
    options?: ISortByOptions
): number => {
    return options?.order && options.order !== 'ascending'
        ? result * -1
        : result;
};

export const sortBy = <T>(list: T[], options?: ISortByOptions): T[] => {
    return list.sort((a: T, b: T): number =>
        handleOptions(handleOrdering(a, b, options), options)
    );
};

export const sortByKey = <T extends Record<string, any>>(
    list: T[],
    key: T[keyof T] | T[keyof T][],
    options?: ISortByOptions
): T[] => {
    const keys = (typeof key === 'string' ? [key] : [...key]).reverse();

    for (const key of keys) {
        list.sort((a: T, b: T): number =>
            handleOptions(handleOrdering(a?.[key], b?.[key], options), options)
        );
    }

    return list;
};
