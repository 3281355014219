import { getScrollPosition } from './get-scroll-position';
import { IRect, IScrollRect, IPosition } from './typings';

export function getElementRect(
    element: HTMLElement,
    containerRect?: IScrollRect
): IRect {
    const {
        top: boxTop,
        left: boxLeft,
        width,
        height,
    } = element.getBoundingClientRect();

    let scrollPosition: IPosition;

    if (containerRect) {
        scrollPosition = {
            top: -containerRect.top,
            left: -containerRect.left,
        };
    } else {
        scrollPosition = getScrollPosition();
    }

    const top = Math.round(boxTop + scrollPosition.top);
    const left = Math.round(boxLeft + scrollPosition.left);

    return { top, left, width: Math.round(width), height: Math.round(height) };
}
