import React, { useCallback, forwardRef, ChangeEvent } from 'react';

interface ITextInput {
    value?: string;
    placeholder?: string;
    className?: string;
    name?: string;
    maxLength?: number;
    onChange?: (
        value: Record<string, string>,
        event: ChangeEvent<HTMLInputElement>
    ) => void;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
    (
        {
            value,
            placeholder,
            onChange,
            name = 'value',
            maxLength,
            className,
        }: ITextInput,
        ref
    ) => {
        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) =>
                onChange?.({ [name]: event.target.value }, event),
            [onChange, name]
        );

        return (
            <input
                ref={ref}
                value={value?.toString() || ''}
                placeholder={placeholder}
                onChange={handleChange}
                className={className}
                maxLength={maxLength}
            />
        );
    }
);
