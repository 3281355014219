import React, { Fragment, ReactNode } from 'react';
import { IAutocompleteState } from '../typings.autocomplete';

const highlightDifference = (
    markType: IAutocompleteState['markType'],
    value: string,
    list: IAutocompleteState['list']
): IAutocompleteState['displayList'] => {
    if (!value || markType === 'none') return list;

    const re = new RegExp(`(${value.replace(/ +/g, '|')})`, 'ig');
    const separator = '[]';

    const handleId = (id: number): boolean =>
        id % 2 === (markType === 'match' ? 1 : 0);

    const GenerateDisplayItem = (item: string, id: number): ReactNode => {
        if (handleId(id)) return <b key={id}>{item}</b>;

        return <Fragment key={id}>{item}</Fragment>;
    };

    return list.map(({ display, id }) => ({
        id: id.toString(),
        display: display
            .replace(re, `${separator}$1${separator}`)
            .split(separator)
            .map(GenerateDisplayItem),
    }));
};

export const generateDisplayList = (
    style: IAutocompleteState['style'],
    markType: IAutocompleteState['markType'],
    value: string,
    list: IAutocompleteState['list']
): IAutocompleteState['displayList'] => {
    if (style === 'search') return highlightDifference(markType, value, list);

    const valueTest = new RegExp(value, 'gi');
    const filteredList = list.filter((item) => valueTest.test(item.display));

    return highlightDifference(markType, value, filteredList);
};
