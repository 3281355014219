import React, { forwardRef, ChangeEvent, InputHTMLAttributes } from 'react';
import styles from './input-with-header.module.scss';
import { Spinner } from 'components/feedback-indicators/spinner';
import { classNames } from 'utils';
import { Icon } from 'components/images-icons/icon';

interface IInputWithHeader
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    label: string;
    useSpinner?: boolean;
    useButton?: boolean;
    onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
    onClickButton?: (
        value: IInputWithHeader['value'],
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
}

export const InputWithHeader = forwardRef<HTMLInputElement, IInputWithHeader>(
    (
        {
            label,
            value,
            placeholder,
            useSpinner,
            useButton,
            onChange,
            onClickButton,
            ...htmlRestProps
        }: IInputWithHeader,
        ref
    ) => {
        const className = classNames(styles.container, {
            [styles.withSpinner]: !!useSpinner,
            [styles.withButton]: !!useButton,
        });

        const handleChange = onChange
            ? (event: ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value, event)
            : undefined;

        const handleButtonClick = onClickButton
            ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  onClickButton(value, event)
            : undefined;

        return (
            <div className={className}>
                {useSpinner && <Spinner className={styles.spinner} />}
                <label>{label}</label>
                <input
                    ref={ref}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                    {...htmlRestProps}
                />
                {useButton && (
                    <button
                        className={styles.button}
                        onClick={handleButtonClick}
                    >
                        <Icon icon="chevron-right" />
                    </button>
                )}
            </div>
        );
    }
);
