import React from 'react';
import { TooltipContainer } from './overlays/tooltip';
import { LayoutHelper } from 'features/layout/helper';
import { LoadingBar } from './feedback-indicators/loading-bar';
import { Spinner } from './feedback-indicators/spinner';
import styles from 'features/layout/layout.module.scss';

export const ComponentHelper = () => {
    return (
        <>
            <Spinner className={styles.spinner} />
            <LoadingBar />
            <TooltipContainer />
            <LayoutHelper />
        </>
    );
};
