import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
    LocalStorageTypes,
    getFromLocalStorage,
    setFromLocalStorage,
} from 'utils';
import { getReferrer } from 'utils/track/index';

export const stateScope: LocalStorageTypes = 'layout';

interface ILayout {
    theme: 'light' | 'dark';
}

const getThemeFromLocalStorage = (): ILayout['theme'] => {
    getReferrer();
    
    return getFromLocalStorage<ILayout['theme']>(stateScope, 'light');
};

const saveThemeToLocalStorage = (theme: ILayout['theme']): void => {
    setFromLocalStorage(stateScope, theme);
};

const getCurrentTheme = (): ILayout['theme'] => {
    const theme = getThemeFromLocalStorage();

    if (theme === 'dark') return theme;

    const hours = new Date().getHours();

    return hours > 20 || hours < 8 ? 'dark' : 'light';
};

const initialState: ILayout = {
    theme: getCurrentTheme(),
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        toggleTheme: (state): void => {
            state.theme = state.theme === 'light' ? 'dark' : 'light';
            saveThemeToLocalStorage(state.theme);
        },
    },
});

export const { reducer, actions } = slice;

export const { toggleTheme } = actions;

export const getTheme = (state: RootState): ILayout['theme'] =>
    state[stateScope].theme;

export const reduced = { [stateScope]: reducer };
