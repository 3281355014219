import { useState, useCallback, useRef, useMemo } from 'react';

type OptionsType = {
    delay?: number;
    openCallback?: () => void;
    closeCallback?: () => void;
};

export interface IUseToggle {
    (defaultValue: boolean, options?: OptionsType): {
        state: boolean;
        open: () => void;
        close: () => void;
        toggle: () => void;
        delayedClose: () => void;
        setState: (value: boolean) => void;
    };
}

export const useToggle: IUseToggle = (defaultValue, options) => {
    const timeout = useRef<number>();
    const [state, setState] = useState<boolean>(defaultValue);

    const { delay, openCallback, closeCallback }: OptionsType = useMemo(
        () => ({
            delay: 200,
            ...options,
        }),
        [options]
    );

    const open = useCallback(() => {
        window.clearTimeout(timeout.current);
        setState(true);

        if (typeof openCallback === 'function') {
            openCallback();
        }
    }, [openCallback]);

    const close = useCallback(() => {
        setState(false);

        if (typeof closeCallback === 'function') {
            closeCallback();
        }
    }, [closeCallback]);

    const toggle = useCallback(() => {
        if (state) close();
        else open();
    }, [state]);

    const delayedClose = useCallback(() => {
        timeout.current = window.setTimeout(close, delay);
    }, [close, delay]);

    return { state, open, close, toggle, delayedClose, setState };
};
