import { IPreviewRecipe } from './typings';
import { getData, Response } from '../get';
import { mapMultipleMediaSrc } from './utils';

type SearchParameters = {
    published?: boolean;
    //personal?: boolean; // TODO: Missing backend
    categoryID?: number;
    onlyWithPicture?: boolean;
    page?: number;
    pageSize?: number;
    limit?: number;
    random?: boolean;
    excludeIDs?: number[];
    includeIngredients?: string[];
    excludeIngredients?: string[];
};

export function search(
    searchParameters: SearchParameters
): Promise<Response<IPreviewRecipe[]>> {
    return getData<IPreviewRecipe[]>({
        url: 'recipe/search',
        data: searchParameters,
        formatResult: mapMultipleMediaSrc,
    });
}
