import { IState, IReducer } from './types';

export const reducer: IReducer = (state, action): IState => {
    switch (action.type) {
        case 'setActive': {
            return { ...state, currentPath: action.payload };
        }
        case 'setVisible': {
            return { ...state, currentPath: action.payload };
        }
        case 'setPath': {
            return { ...state, path: action.payload };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};
