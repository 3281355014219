import { IRouteProps } from 'typings';

export const getPath = (
    pathname: string = window.location.pathname
): IRouteProps['path'] => {
    const paths = pathname?.split('/').filter(Boolean);
    const controller = paths?.[0];

    const path: IRouteProps['path'] = {
        paths,
        pathname,
        controller,
    };

    return path;
};
