import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTheme, toggleTheme } from 'store/layout';
import { Icon } from 'components/images-icons/icon';

export const ToggleTheme: FunctionComponent = () => {
    const theme = useSelector(getTheme);
    const dispatch = useDispatch();

    const onClick = () => dispatch(toggleTheme());

    return (
        <div onClick={onClick}>
            <Icon icon={theme === 'light' ? 'moon' : 'sun'} />
        </div>
    );
};
