import React, {
    FunctionComponent,
    ReactNode,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import styles from './tooltip.module.scss';
import { classNames, debounce, generateId, getElementRect } from 'utils';
import { IChildren, IClassName } from 'typings';
import { hover, remove } from './slice';

export interface ITooltip extends IChildren, IClassName {
    title: ReactNode;
    placement?: 'left' | 'right' | 'top' | 'bottom';
    //trigger?: ('click' | 'hover' | Function)[];
    delay?: number;
}

export const Tooltip: FunctionComponent<ITooltip> = ({
    children,
    className,
    title,
    placement = 'top',
    delay = 50,
}: ITooltip) => {
    const id = useRef<string>('');
    const tooltipInitiater = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const triggerClassName = classNames(styles.trigger, className);

    useEffect(() => {
        id.current = generateId();

        return () => {
            dispatch(remove(id.current));
        };
    }, []);

    const handleEnter = useCallback(
        debounce(() => {
            if (tooltipInitiater.current) {
                const data = {
                    id: id.current,
                    position: getElementRect(tooltipInitiater.current),
                    content: title,
                    placement,
                };

                dispatch(hover(data, delay));
            }
        }, 200),
        [id.current, tooltipInitiater.current]
    );

    const handleLeave = useCallback(
        debounce(() => dispatch(remove(id.current)), 200),
        [id.current]
    );

    return (
        <div
            className={triggerClassName}
            ref={tooltipInitiater}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            {children}
        </div>
    );
};
