import React, { forwardRef, ChangeEvent, InputHTMLAttributes } from 'react';
import styles from './input.module.scss';
import { Spinner } from 'components/feedback-indicators/spinner';
import { classNames } from 'utils';
import { Icon } from 'components/images-icons/icon';
import { ValidationType } from 'utils/validate';
import { NumberInput } from './number-input';
import { TextInput } from './text-input';

interface IInput {
    value?: string | number;
    placeholder?: string;
    label?: string;
    useSpinner?: boolean;
    useButton?: boolean;
    className?: string;
    name?: string;
    type?: ValidationType;
    maxLength?: number;
    onChange?: (
        value: Record<string, string | number>,
        event: ChangeEvent<HTMLInputElement>
    ) => void;
    onClickButton?: (
        value: InputHTMLAttributes<HTMLInputElement>['value'],
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
}

export const Input = forwardRef<HTMLInputElement, IInput>(
    (
        {
            label,
            value,
            placeholder,
            useSpinner,
            useButton,
            onChange,
            onClickButton,
            className,
            name = 'value',
            type = 'default',
            maxLength,
        }: IInput,
        ref
    ) => {
        const containerClassName = classNames(
            styles.container,
            {
                [styles.withSpinner]: !!useSpinner,
                [styles.withButton]: !!useButton,
                [styles.withLabel]: !!label,
            },
            className
        );

        const handleButtonClick = onClickButton
            ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  onClickButton(value, event)
            : undefined;

        return (
            <div className={containerClassName}>
                {!!label && <label className={styles.label}>{label}</label>}
                {useSpinner && <Spinner className={styles.spinner} />}
                {type === 'default' ? (
                    <TextInput
                        ref={ref}
                        name={name}
                        value={value?.toString() || ''}
                        placeholder={placeholder}
                        onChange={onChange}
                        className={styles.input}
                        maxLength={maxLength}
                    />
                ) : (
                    <NumberInput
                        ref={ref}
                        name={name}
                        value={typeof value === 'number' ? value : undefined}
                        placeholder={placeholder}
                        onChange={onChange}
                        className={styles.input}
                        maxLength={maxLength}
                        type={type}
                    />
                )}
                {useButton && (
                    <button
                        className={styles.button}
                        onClick={handleButtonClick}
                    >
                        <Icon icon="chevron-right" />
                    </button>
                )}
            </div>
        );
    }
);
