import React, { useEffect, useState } from 'react';
import { api } from 'api';
import { IIngredientList } from 'api/ingredient';
import { PreviewRecipe } from 'components/structure/preview-recipe';
import { Button } from 'components/actions/button';
import { Link } from 'utils';
import { usePath } from 'hooks';
import { Breadcrumb } from 'components/navigation/breadcrumb';
import { Badge } from 'components/titles-text/badge';
import { AlphabeticalList } from 'components/lists-tables/alphabetical-list';

export function Ingredients() {
    const path = usePath();

    const [ingredients, setIngredients] = useState<IIngredientList>();
    const type = path.pathname.replace(/^\/ingredienser(\/|$)/, '');

    useEffect(() => {
        const load = async () => {
            const loadedIngredients = await api.ingredient.recipes(type);

            if (loadedIngredients.success) {
                setIngredients(loadedIngredients.result);
            }
        };

        load();
    }, [type]);

    if (!ingredients) {
        return <>Loader?</>;
    }

    const recipeList = (
        <AlphabeticalList
            list={ingredients.ingredients.map((ingredient) => ({
                title: ingredient.title,
                component: (
                    <div key={ingredient.uri}>
                        <Link to={`/ingredienser/${ingredient.uri}`}>
                            {ingredient.title}
                            <Badge
                                type="pill"
                                color="primary"
                                title={`Indeholder ${ingredient.recipeCount} ${
                                    ingredient.recipeCount === 1
                                        ? 'opskrift'
                                        : 'opskrifter'
                                }`}
                            >
                                {ingredient.recipeCount}
                            </Badge>
                        </Link>
                    </div>
                ),
            }))}
        />
    );

    if (!ingredients.ingredient) {
        return (
            <>
                <h1 className="center">Alle ingredienser</h1>
                <p className="center">
                    Her finder du en oversigt over alle ingredienser
                </p>
                {recipeList}
            </>
        );
    }

    return (
        <>
            <Breadcrumb
                base={{ title: 'Alle ingredienser', link: '/ingredienser' }}
                list={ingredients.ingredient.breadcrumb}
            />
            <h1 itemProp="name" className="center">
                {ingredients.ingredient.title}
                <Badge type="pill" color="primary">
                    {ingredients.ingredient.recipeCount}
                </Badge>
            </h1>
            <p className="center">
                Alle ingredienser under denne kategori kan ses herunder.
            </p>
            <p className="center">
                I bunden findes alle opskrifter der indeholder{' '}
                {ingredients.ingredient.title.toLowerCase()}.
            </p>
            {recipeList}
            <div>
                {!!ingredients.recipes?.length &&
                    ingredients.recipes.map((recipe) => (
                        <PreviewRecipe key={recipe.uri} {...recipe} />
                    ))}
            </div>
            {ingredients.recipes?.length === 3 && (
                <Button center>
                    Se alle opskrifter med {ingredients.ingredient.title}
                </Button>
            )}
        </>
    );
}
