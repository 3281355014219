export const types = [
    '',
    'l',
    'dl',
    'cl',
    'ml',
    'spsk',
    'tsk',
    'knsp',
    'cm',
    'mm',
    'kg',
    'g',
    'stk',
    'glas',
    'pk',
];

export const conversionTypes: Record<
    string,
    {
        name: string;
        key: string;
        list: { key: string; name: string; type?: 'metric' | 'imperial' }[];
    }
> = {
    volume: {
        name: 'Volumen / Liter',
        key: 'l',
        list: [
            { key: 'l', name: 'Liter', type: 'metric' },
            { key: 'dl', name: 'Deciliter', type: 'metric' },
            { key: 'cl', name: 'Centiliter', type: 'metric' },
            { key: 'ml', name: 'Milliliter', type: 'metric' },
            { key: 'spsk', name: 'Spiseske', type: 'metric' },
            { key: 'tsk', name: 'Teske', type: 'metric' },
            { key: 'knsp', name: 'Knivspids', type: 'metric' },
            { key: 'ounce_l', name: 'Flydende ounce', type: 'imperial' },
            { key: 'cup', name: 'Kop', type: 'imperial' },
            { key: 'pint', name: 'Pint', type: 'imperial' },
            { key: 'quart', name: 'Kvart', type: 'imperial' },
            { key: 'gallon', name: 'Gallon', type: 'imperial' },
        ],
    },
    mass: {
        name: 'Masse / Vægt',
        key: 'kg',
        list: [
            { key: 'kg', name: 'Kilogram', type: 'metric' },
            { key: 'g', name: 'Gram', type: 'metric' },
            { key: 'ounce_g', name: 'Ounce', type: 'imperial' },
            { key: 'stone', name: 'Sten', type: 'imperial' },
            { key: 'pound', name: 'Pund', type: 'imperial' },
        ],
    },
    length: {
        name: 'Længde',
        key: 'cm',
        list: [
            { key: 'cm', name: 'Centimeter', type: 'metric' },
            { key: 'mm', name: 'Millimeter', type: 'metric' },
            { key: 'inch', name: 'Tommer', type: 'imperial' },
            { key: 'foot', name: 'Fod', type: 'imperial' },
        ],
    },
    temperature: {
        name: 'Temperatur',
        key: 'c',
        list: [
            { key: 'c', name: 'Celsius' },
            { key: 'f', name: 'Fahrenheit' },
            { key: 'k', name: 'Kelvin' },
        ],
    },
};

export const converTypes = {
    volume: [
        'l',
        'dl',
        'cl',
        'ml',
        'spsk',
        'tsk',
        'knsp',
        'ounce_l',
        'cup',
        'pint',
        'quart',
        'gallon',
    ],
    mass: ['kg', 'g', 'ounce_g', 'stone', 'pound'],
    length: ['cm', 'mm', 'inch', 'foot'],
    temperature: ['c', 'f', 'k'],
};

export const isApprovedConvertedType = (type: string): boolean => {
    return (
        converTypes.volume.includes(type) ||
        converTypes.mass.includes(type) ||
        converTypes.length.includes(type)
    );
};

export function getUnitType(type: string): string {
    switch (type) {
        case 'l':
        case 'dl':
        case 'cl':
        case 'ml':
        case 'spsk':
        case 'tsk':
        case 'knsp':
        case 'ounce_l':
        case 'cup':
        case 'pint':
        case 'quart':
        case 'gallon':
            return 'l';

        case 'cm':
        case 'mm':
        case 'inch':
        case 'foot':
            return 'cm';

        case 'kg':
        case 'g':
        case 'ounce_g':
        case 'stone':
        case 'pound':
            return 'kg';

        case 'c':
        case 'f':
        case 'k':
            return 'c';

        case 'stk':
        default:
            return 'stk';
    }
}

export function convertUnitType(
    amount: number,
    fromType: string,
    toType: string
): number {
    const unitType = getUnitType(fromType);

    if (unitType !== getUnitType(toType)) return amount;

    return convertFromUnitType(convertToUnitType(amount, fromType), toType);
}

export function convertToUnitType(amount: number, unit: string): number {
    const unitType = getUnitType(unit);

    if (unitType === unit) return amount;

    if (unitType === 'l') {
        switch (unit) {
            case 'dl':
                return amount / 10;
            case 'cl':
                return amount / 100;
            case 'ml':
                return amount / 1000;
            case 'spsk':
                return amount / (1000 / 15);
            case 'tsk':
                return amount / (1000 / 5);
            case 'knsp':
                return amount / (1000 / 2.5);

            case 'ounce_l':
                return amount / (1000 / 28.41);
            case 'cup':
                return amount / (1000 / 284.1);
            case 'pint':
                return amount / (1000 / 568.26);
            case 'quart':
                return amount / (1000 / 1136.52);
            case 'gallon':
                return amount / (1000 / 4546.09);
        }
    } else if (unitType === 'cm') {
        switch (unit) {
            case 'mm':
                return amount / 10;

            case 'inch':
                return (amount / 10) * 25.4;
            case 'foot':
                return (amount / 10) * 304.8;
        }
    } else if (unitType === 'kg') {
        switch (unit) {
            case 'g':
                return amount / 1000;

            case 'ounce_g':
                return (amount / 1000) * 28.349523125;
            case 'stone':
                return amount * 0.45359237;
            case 'pound':
                return amount * 6.35029318;
        }
    } else if (unitType === 'c') {
        switch (unit) {
            case 'f':
                return (amount - 32) * (5 / 9);
            case 'k':
                return amount - 273.15;
        }
    }

    return amount;
}

export function convertFromUnitType(amount: number, unit: string): number {
    const unitType = getUnitType(unit);

    if (unitType === unit) return amount;

    if (unitType === 'l') {
        switch (unit) {
            case 'dl':
                return amount * 10;
            case 'cl':
                return amount * 100;
            case 'ml':
                return amount * 1000;
            case 'spsk':
                return amount * (1000 / 15);
            case 'tsk':
                return amount * (1000 / 5);
            case 'knsp':
                return amount * (1000 / 2.5);

            case 'ounce_l':
                return amount * (1000 / 28.41);
            case 'cup':
                return amount * (1000 / 284.1);
            case 'pint':
                return amount * (1000 / 568.26);
            case 'quart':
                return amount * (1000 / 1136.52);
            case 'gallon':
                return amount * (1000 / 4546.09);
        }
    } else if (unitType === 'cm') {
        switch (unit) {
            case 'mm':
                return amount * 10;

            case 'inch':
                return amount * 10 * 25.4;
            case 'foot':
                return amount * 10 * 304.8;
        }
    } else if (unitType === 'kg') {
        switch (unit) {
            case 'g':
                return amount * 1000;

            case 'ounce_g':
                return amount * 1000 * 28.349523125;
            case 'stone':
                return amount / 0.45359237;
            case 'pound':
                return amount / 6.35029318;
        }
    } else if (unitType === 'c') {
        switch (unit) {
            case 'f':
                return amount * (9 / 5) + 32;
            case 'k':
                return amount + 273.15;
        }
    }

    return amount;
}

export function convertToEven(
    amount: string,
    type: string
): { amount: number; type: string } | false {
    if (validate_numeric(amount)) return { amount: parseInt(amount), type };
    if (!validate_numeric_decimal(amount)) return false;

    const index = types.findIndex((t) => t === type) + 1;

    if (types.length <= index || getUnitType(type) != getUnitType(types[index]))
        return { amount: parseInt(amount), type };

    return {
        amount: convertUnitType(parseInt(amount), type, types[index]),
        type: types[index],
    };
}

function validate_numeric(text: string, performZeroCheck = true): boolean {
    if (performZeroCheck) return /^(0|[1-9][\d]*)$/.test(text);

    return /^[\d]+$/.test(text);
}

function validate_numeric_decimal(text: string): boolean {
    return /^(0|[1-9][\d]*)((,|\.)[\d]+)?$/.test(text);
}
