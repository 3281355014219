import React from 'react';
import { IChildren, IRouteProps } from 'typings';
import { Topbar } from './topbar';
import styles from './layout.module.scss';
import { Navigation } from 'components/navigation/navigation';

interface IWrapper extends IChildren, IRouteProps {
    fullPage?: boolean;
}

export const Wrapper = ({ children }: IWrapper) => {
    return (
        <div className={styles.wrapper}>
            <div id="content" className={styles.content}>
                <div className={styles.innerContent}>{children}</div>
            </div>
            <Topbar />
            <Navigation />
        </div>
    );
};
