import React from 'react';
import { styles } from './styles';
import { Ingredients, Sections } from './components';
import { useCurrentRecipe } from './hooks';

export function Recipe() {
    const recipe = useCurrentRecipe();

    return (
        <>
            <h1 itemProp="name" className={styles.name}>
                {recipe.title}
            </h1>
            <h4 itemProp="description" className={styles.description}>
                {recipe.description}
            </h4>
            {recipe.mediaSrc && (
                <div className={styles.image}>
                    <img alt={recipe.title} src={recipe.mediaSrc} />
                </div>
            )}
            <hr />
            <div className={styles.container}>
                <div className={styles.directions}>
                    <h2>Fremgangmåde</h2>
                    <Sections />
                </div>
                <div className={styles.ingredients}>
                    <h2>Ingredienser</h2>
                    <Ingredients />
                </div>
            </div>
        </>
    );
}
