import React, {
    useEffect,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
} from 'react';
import styles from './autocomplete.module.scss';
import { IClassName } from 'typings';
import { InputWithHeader } from 'components/forms/input-with-header';
import { debounce } from 'utils';
import {
    IAutocompleteState,
    SearchType,
    OnChangeType,
    AutocompleteOptionsType,
} from './typings.autocomplete';
import { useAutocompleteState } from './use-autocomplete-state';

interface IAutocomplete
    extends IClassName,
        Partial<Pick<IAutocompleteState, 'style'>> {
    search: SearchType;
    label: string;
    options?: AutocompleteOptionsType;
    onChange?: OnChangeType;
    clearOnSelect?: boolean;
}

export const Autocomplete: FunctionComponent<IAutocomplete> = ({
    label,
    className,
    search,
    style = 'search',
    options,
    onChange,
}: IAutocomplete) => {
    const {
        state: {
            value,
            list,
            displayList,
            currentActiveItem,
            isDropdownOpen,
            isLoading,
            isInitiated,
        },
        actions: {
            setValue,
            setCurrentActiveItem,
            moveActiveItemUp,
            moveActiveItemDown,
            setFocus,
            setBlur,
            setList,
            updateDisplayList,
        },
    } = useAutocompleteState({ options, style });

    useEffect(() => {
        if (style === 'preload') {
            search(setList, value);
        }
    }, []);

    useEffect(() => {
        if (style === 'load' && isInitiated) {
            search(setList, value);
        }
    }, [isInitiated]);

    const handleSearch = useCallback(debounce(search), [search]);
    const handleLoadedSearch = useCallback(debounce(updateDisplayList, 50), []);

    const handleChange = (value: string) => {
        setValue(value);

        if (style === 'search') {
            handleSearch(setList, value);
        } else {
            handleLoadedSearch();
        }
    };

    const handleSetActiveItem = (index: number | null) => {
        const currentIndex = index ?? currentActiveItem ?? 0;
        const id = displayList?.[currentIndex]?.id;
        const item = list.find((item) => item.id === id);

        if (item) {
            onChange?.(item);
        }

        setCurrentActiveItem(index);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        const { key } = event;

        if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'Enter') {
            switch (key) {
                case 'ArrowUp':
                    moveActiveItemUp();
                    break;
                case 'ArrowDown':
                    moveActiveItemDown();
                    break;
                case 'Enter':
                    handleSetActiveItem(null);
                    break;
            }

            event.preventDefault();
            event.stopPropagation();
        }
    };

    const containerClassName = [styles.container, className]
        .filter(Boolean)
        .join(' ');

    return (
        <div
            className={containerClassName}
            onFocus={setFocus}
            onBlur={setBlur}
            onKeyDown={handleKeyDown}
        >
            <InputWithHeader
                label={label}
                onChange={handleChange}
                value={value}
                useSpinner={isLoading}
            />
            {isDropdownOpen && (
                <ul>
                    {displayList.map((item, index) => (
                        <li
                            key={item.id}
                            onMouseDown={() => handleSetActiveItem(index)}
                            className={
                                index === currentActiveItem ? styles.active : ''
                            }
                        >
                            {item.display}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
