import { IPreviewRecipe } from './typings';
import { postData, Response } from '../post';
import { mapMultipleMediaSrc } from './utils';

export function all(): Promise<Response<IPreviewRecipe[]>> {
    return postData<IPreviewRecipe[]>({
        url: 'recipe/all',
        formatResult: mapMultipleMediaSrc,
    });
}
