import { Input } from 'components/forms/input';
import { Select } from 'components/forms/select';
import React from 'react';
import styles from './convert.module.scss';
import { useConvertState } from './use-convert-state';

export function Convert() {
    const {
        state: {
            categoryTypes,
            categoryType,
            categoryList,
            firstSelectedType,
            secondSelectedType,
            firstSelectedValue,
            secondSelectedValue,
        },
        actions: {
            setCategoryType,
            setFirstSelectionType,
            setSecondSelectionType,
            setFirstSelectionValue,
            setSecondSelectionValue,
        },
    } = useConvertState();

    return (
        <>
            <h1 className="center">Konverter måleenheder</h1>
            <p className="center">
                Den lette måde at oversætte de udenlandske opskrifter.
            </p>
            <div className={styles.container}>
                <Select
                    // @ts-ignore
                    onChange={setCategoryType}
                    value={categoryType}
                    name="categoryType"
                    list={categoryTypes}
                />
                <div className={styles.fromTo}>
                    <div className={styles.convertionItem}>
                        <Input
                            className={styles.input}
                            type="decimal"
                            value={firstSelectedValue}
                            name="firstSelectedValue"
                            maxLength={10}
                            // @ts-ignore
                            onChange={setFirstSelectionValue}
                        />
                        <Select
                            // @ts-ignore
                            onChange={setFirstSelectionType}
                            value={firstSelectedType}
                            name="firstSelectedType"
                            list={categoryList}
                        />
                    </div>
                    <div className={styles.equal}>=</div>
                    <div className={styles.convertionItem}>
                        <Input
                            className={styles.input}
                            type="decimal"
                            value={secondSelectedValue}
                            name="secondSelectedValue"
                            maxLength={10}
                            // @ts-ignore
                            onChange={setSecondSelectionValue}
                        />
                        <Select
                            // @ts-ignore
                            onChange={setSecondSelectionType}
                            value={secondSelectedType}
                            name="secondSelectedType"
                            list={categoryList}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
