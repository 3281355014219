import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { IPublicRecipe } from 'api/recipe';

export const stateScope = 'recipe';

interface IRecipeState {
    loading: string[];
    failed: string[];
    recipes: {
        [recipeUri: string]: IPublicRecipe;
    };
}

const initialState: IRecipeState = {
    loading: [],
    failed: [],
    recipes: {},
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        loadRecipe: (state, action: PayloadAction<string>): void => {
            state.loading.push(action.payload);
        },
        failedRecipe: (state, action: PayloadAction<string>): void => {
            state.loading = state.loading.filter(
                (uri) => uri !== action.payload
            );
            state.failed.push(action.payload);
        },
        addRecipe: (state, action: PayloadAction<IPublicRecipe>): void => {
            state.loading = state.loading.filter(
                (uri) => uri !== action.payload.uri
            );
            state.recipes[action.payload.uri] = action.payload;
        },
    },
});

export const { reducer, actions } = slice;

export const { addRecipe, loadRecipe, failedRecipe } = actions;

export const getState = (state: RootState): IRecipeState => state[stateScope];

export const reduced = { [stateScope]: reducer };
