import React from 'react';
import { Recipe } from './recipe';
import { useLoadRecipe, loadedState, loadingState } from './hooks';

export function Handler() {
    const recipeState = useLoadRecipe();

    if (recipeState === loadingState) return <>Loading</>;
    if (recipeState === loadedState) return <Recipe />;

    return <>Nope</>;
}
