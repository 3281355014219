import { IConvertState, IConvertReducer } from './typings.convert';
import { getSelectionTypes, convertAmount } from './actions';

export const reducer: IConvertReducer = (state, action): IConvertState => {
    switch (action.type) {
        case 'setCategoryType': {
            const {
                categoryType,
                categoryList,
                firstSelectedType,
                secondSelectedType,
            } = getSelectionTypes(action.payload.categoryType);

            return {
                ...state,
                categoryType,
                categoryList,
                firstSelectedType,
                secondSelectedType,
                firstSelectedValue: 1,
                secondSelectedValue: 1,
            };
        }
        case 'setSelectionType': {
            const { payload } = action;
            const tempState: IConvertState = { ...state, ...payload };

            const updateValues = convertAmount(state, tempState, payload);

            return { ...tempState, ...updateValues };
        }
        case 'setSelectionValue': {
            const updateValues = convertAmount(action.payload, state);

            return { ...state, ...updateValues };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};
