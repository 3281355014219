import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigationActions } from 'store/navigation';
import { Icon } from 'components/images-icons/icon';
import styles from './navigation.module.scss';

export const NavigationButton: FunctionComponent = () => {
    const {
        openNavigation,
        closeNavigation,
        toggleNavigation,
    } = useNavigationActions();

    return (
        <button className={styles.button} onClick={toggleNavigation}>
            <Icon icon="bars" />
        </button>
    );
};
