import { RefObject, useEffect } from 'react';
import { throttle } from 'utils';

// NEEDS WORK! mouseleave does not work

export type IEvent = 'mouseenter' | 'mouseleave';
export type Target = RefObject<HTMLElement>;
export type Callback = (event?: IEvent, target?: Target) => void;

export interface IUseEventListener {
    (target: Target, callback: Callback, events: IEvent[]): void;
}

type IListeners = {
    [event in IEvent]?: {
        target: Target;
        callback: Callback;
    }[];
};
const listeners: IListeners = {};

const handleEvent = throttle((event: any) => {
    //console.log(event);
    const type = event.type as IEvent;

    if (!listeners[type]) return;

    // TODO: Throttle this instead. So it throttles for each specific type, not all events – might override some otherwise
    listeners[type]?.forEach(({ target, callback }) => {
        const eventTarget =
            type === 'mouseleave' ? event.fromElement : event.target;

        if (
            target.current !== eventTarget &&
            (type !== 'mouseleave' || !eventTarget.contains(target.current))
        )
            return;
        //console.log(target.current, eventTarget);

        callback(type, target);
    });
}, 100);

const addListener = (event: IEvent, target: Target, callback: Callback) => {
    //console.log('adding', listeners);

    if (!listeners[event]?.length) {
        listeners[event] = [];
        console.log(`Adding ${event} listener`);
        document.addEventListener(event, handleEvent, true);
    }

    listeners[event]?.push({
        target,
        callback,
    });
};

const removeListener = (event: IEvent, target: Target, callback: Callback) => {
    //console.log('removing', listeners, listeners[event]);

    if (!listeners[event]) return;

    const listenerIndex = listeners[event]!.findIndex(
        (listener) =>
            listener.target === target && listener.callback === callback
    );

    if (listenerIndex !== -1) {
        listeners[event]!.splice(listenerIndex, 1);
    } else {
        console.log('error removing!!');
    }

    if (!listeners[event]!.length) {
        console.log(`Removeing ${event} listener`);
        document.removeEventListener(event, handleEvent, true);
    }
};

const addListeners = (events: IEvent[], target: Target, callback: Callback) =>
    events.forEach((event) => addListener(event, target, callback));

const removeListeners = (
    events: IEvent[],
    target: Target,
    callback: Callback
) => events.forEach((event) => removeListener(event, target, callback));

export const useEventListener: IUseEventListener = (
    target,
    callback,
    events
) => {
    //console.log(listeners);

    useEffect(() => {
        if (target.current !== null) {
            addListeners(events, target, callback);

            return () => removeListeners(events, target, callback);
        }
    }, [target, callback, events]);
};
