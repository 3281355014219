import React from 'react';
import { IChildren } from 'typings';
import styles from './formatting.module.scss';
import { classNames } from 'utils';

export const Bolder = ({ children }: IChildren) => (
    <span className={styles.bolder}>{children}</span>
);

export const Spacer = () => <hr className={styles.spacer} />;

export const H1 = ({ children, center }: IChildren & { center?: boolean }) => (
    <h1 className={classNames({ [styles.center]: !!center })}>{children}</h1>
);

export const H2 = ({ children, center }: IChildren & { center?: boolean }) => (
    <h2 className={classNames({ [styles.center]: !!center })}>{children}</h2>
);

export const H3 = ({ children, center }: IChildren & { center?: boolean }) => (
    <h3 className={classNames({ [styles.center]: !!center })}>{children}</h3>
);

export const H4 = ({ children, center }: IChildren & { center?: boolean }) => (
    <h4 className={classNames({ [styles.center]: !!center })}>{children}</h4>
);
