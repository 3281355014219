import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { generateBrowserSpecificId } from 'utils/track';

export const stateScope = 'user';

interface IUser {
    uuid: string;
}

const initialState: IUser = {
    uuid: generateBrowserSpecificId(),
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {},
});

export const { reducer, actions } = slice;

export const getUserState = (state: RootState): IUser => state[stateScope];

export const reduced = { [stateScope]: reducer };
