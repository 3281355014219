import React from 'react';
import { useCurrentRecipe } from '../hooks';
import { Section } from './section';

export function Sections() {
    const { sections } = useCurrentRecipe();

    return (
        <>
            {sections?.map((section, index) => (
                <Section key={index} {...section} />
            ))}
        </>
    );
}
