import React, { useRef, useMemo, MutableRefObject } from 'react';
import { isVisible, scrollToElement } from 'utils';
import styles from './alphabetical-list.module.scss';
import { ListItem } from './typings';
import { getList, getKeys } from './helpers';
import { CategoryList } from './category-list';

interface IAlphabeticalList {
    list: ListItem[];
}

const overlay = { top: 57 };

const getCurrentCategory = (
    refs: MutableRefObject<Array<HTMLDivElement | null>>,
    keys: string[]
) => {
    const length = refs?.current?.length ?? 0;

    for (let index = 0; index < length; index++) {
        const ref = refs?.current[index];

        if (!ref) continue;

        if (isVisible(ref, overlay)) return keys?.[index];
    }

    return '';
};

export const AlphabeticalList = ({ list }: IAlphabeticalList) => {
    const categoryRefs = useRef<Array<HTMLDivElement | null>>([]);

    const categoryKeys = useMemo(() => getKeys(list), [list]);
    const categories = useMemo(() => getList(categoryKeys, list), [
        categoryKeys,
    ]);

    const handleGetCurrenCategory = () =>
        getCurrentCategory(categoryRefs, categoryKeys);

    const handleClick = (index: number) => {
        const ref = categoryRefs.current?.[index];

        if (!ref) return;

        scrollToElement(ref, { ...overlay, top: overlay.top - 2 });
    };

    return (
        <>
            <CategoryList
                categoryKeys={categoryKeys}
                categories={categories}
                onClick={handleClick}
                getCurrentCategory={handleGetCurrenCategory}
            />
            {categories.map(({ category, components }, index) => (
                <div
                    key={category}
                    ref={(el: HTMLDivElement) =>
                        (categoryRefs.current[index] = el)
                    }
                    className={styles.category}
                >
                    <div className={styles.categoryKey}>{category}</div>
                    <div className={styles.list}>{components}</div>
                </div>
            ))}
        </>
    );
};
