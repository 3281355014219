import { ListItem, CategorizedListItem } from '../typings';

export const getList = (
    keys: string[],
    list: ListItem[]
): CategorizedListItem[] =>
    keys.map((category) => ({
        category,
        components: list
            .filter(({ title }) => title[0].toUpperCase() === category)
            .sortByKey('title')
            .map(({ component }) => component),
    }));
