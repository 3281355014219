import { useState, useEffect } from 'react';
import { throttle } from 'utils';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type WindowSizeType = {
    width: number;
    height: number;
    breakpoint: Breakpoints;
};

const breakpoints: Record<Breakpoints, number> = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1800,
};

const getBreakpoint = (width: number): Breakpoints => {
    if (width < breakpoints.sm) return 'xs';
    else if (width < breakpoints.md) return 'sm';
    else if (width < breakpoints.lg) return 'md';
    else if (width < breakpoints.xl) return 'lg';
    else if (width < breakpoints.xxl) return 'xl';

    return 'xxl';
};

export function useWindowSize(): WindowSizeType {
    const [windowSize, setWindowSize] = useState<WindowSizeType>({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpoint(window.innerWidth),
    });

    const handleResize = throttle(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            breakpoint: getBreakpoint(window.innerWidth),
        });
    }, 400);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return (): void => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
