import {
    IAutocompleteState,
    IAutocompleteReducer,
    IAutocompleteItem,
} from './typings.autocomplete';
import { generateDisplayList } from './actions/generate-display-list';

export const reducer: IAutocompleteReducer = (
    state,
    action
): IAutocompleteState => {
    switch (action.type) {
        case 'setValue': {
            let isLoading = state.isLoading;

            if (state.style === 'search') {
                isLoading = true;
            } else if (state.style === 'load') {
                isLoading = state.isInitiated ? state.isLoading : true;
            }

            return {
                ...state,
                value: action.payload,
                lastWrittenValue: action.payload,
                isDropdownOpen: !!state.displayList.length,
                isLoading,
                isInitiated: true,
            };
        }
        case 'setCurrentActiveItem': {
            const index = action.payload ?? state.currentActiveItem;

            if (index === null) return state;

            const selectedItemId = state.displayList[index].id;
            const selectedItem = state.list.find(
                (item) => item.id === selectedItemId
            ) as IAutocompleteItem;

            return {
                ...state,
                currentActiveItem: null,
                value: state.clearOnSelect ? '' : selectedItem.display,
                isDropdownOpen: state.clearOnSelect
                    ? false
                    : state.isDropdownOpen,
            };
        }
        case 'moveActiveItemUp': {
            const { length } = state.list;

            if (!length || state.currentActiveItem === null) return state;

            const currentActiveItem = state.currentActiveItem - 1;

            if (currentActiveItem < 0) {
                return {
                    ...state,
                    currentActiveItem: null,
                    value: state.lastWrittenValue,
                };
            }

            const selectedItemId = state.displayList[currentActiveItem].id;
            const selectedItem = state.list.find(
                (item) => item.id === selectedItemId
            ) as IAutocompleteItem;

            return {
                ...state,
                currentActiveItem,
                value: selectedItem.display,
            };
        }
        case 'moveActiveItemDown': {
            const { length } = state.list;

            if (!length) return state;

            const currentActiveItem =
                state.currentActiveItem === null
                    ? 0
                    : Math.min(state.currentActiveItem + 1, length - 1);

            const selectedItemId = state.displayList[currentActiveItem].id;
            const selectedItem = state.list.find(
                (item) => item.id === selectedItemId
            ) as IAutocompleteItem;

            return {
                ...state,
                currentActiveItem,
                value: selectedItem.display,
            };
        }
        case 'setFocus': {
            return {
                ...state,
                isDropdownOpen: !!state.value && !!state.list.length,
                inFocus: true,
            };
        }
        case 'setBlur': {
            return {
                ...state,
                isDropdownOpen: false,
                currentActiveItem: null,
                inFocus: false,
            };
        }
        case 'setList': {
            const displayList = generateDisplayList(
                state.style,
                state.markType,
                state.value,
                action.payload
            );

            return {
                ...state,
                list: action.payload,
                displayList,
                isDropdownOpen: state.inFocus && !!displayList.length,
                isLoading: false,
            };
        }
        case 'updateDisplayList': {
            const displayList = generateDisplayList(
                state.style,
                state.markType,
                state.value,
                state.list
            );

            return {
                ...state,
                displayList,
                isDropdownOpen: state.inFocus && !!displayList.length,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};
