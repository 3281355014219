import { api } from 'api';
import { SearchType } from 'components/actions/autocomplete';

export const search: SearchType = async (setList) => {
    const searchedIngredients = await api.search.ingredients();

    if (searchedIngredients.success) {
        const list = searchedIngredients.result.map((ingredient) => ({
            ingredient,
            id: ingredient.id,
            display: ingredient.breadcrumb
                .map((item) => item.title)
                .join(' – '),
        }));

        setList(list);
    }
};
