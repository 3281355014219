import { IFridgeDispatch, IFridgeState } from './typings.fridge';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const actions = (dispatch: IFridgeDispatch) => ({
    setRecipes: (payload: IFridgeState['recipes']): void =>
        dispatch({ type: 'setRecipes', payload }),
    startLoading: (): void => dispatch({ type: 'startLoading' }),
    includeIngredient: (
        payload: IFridgeState['includedIngredients'][0]
    ): void => dispatch({ type: 'includeIngredient', payload }),
    excludeIngredient: (
        payload: IFridgeState['includedIngredients'][0]
    ): void => dispatch({ type: 'excludeIngredient', payload }),
    toggleIngredient: (
        payload: IFridgeState['includedIngredients'][0]['id']
    ): void => dispatch({ type: 'toggleIngredient', payload }),
    removeIngredient: (
        payload: IFridgeState['includedIngredients'][0]['id']
    ): void => dispatch({ type: 'removeIngredient', payload }),
});
