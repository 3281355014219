import React from 'react';
import { LogoColor, LogoShape } from './types';
import logo from './logo.svg';
import { IClassName } from 'typings';

interface ILogo extends IClassName {
    color: LogoColor;
    shape: LogoShape;
}

export const Logo = ({ color, shape, className }: ILogo) => {
    const dimensions = {
        width: shape === 'horizontal' ? 125 : 100,
        height: shape === 'horizontal' ? 50 : 70,
    };

    return (
        <img
            alt="Logo"
            className={className}
            {...dimensions}
            src={`${logo}#${shape[0]}${color === 'white' ? 'w' : ''}`}
        />
    );
};
