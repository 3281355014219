import { api } from 'api';
import { useSelector, useDispatch } from 'react-redux';
import { getState, addRecipe, loadRecipe, failedRecipe } from '../slice';
import { usePath } from 'hooks';

const getRecipe = async (dispatch: any, uri: string): Promise<void> => {
    dispatch(loadRecipe(uri));

    const recipeResponse = await api.recipe.get(uri);

    if (recipeResponse.success) {
        dispatch(addRecipe(recipeResponse.result));
    } else {
        dispatch(failedRecipe(uri));
    }
};

export const loadedState = 'loaded';
export const loadingState = 'loading';
export const failedState = 'failed';

type ReturnType = typeof loadedState | typeof loadingState | typeof failedState;

export function useLoadRecipe(): ReturnType {
    const dispatch = useDispatch();

    const {
        paths: [, uri],
    } = usePath();

    const {
        loading,
        failed,
        recipes: { [uri]: recipe },
    } = useSelector(getState);

    if (recipe) return loadedState;

    if (!uri || failed.includes(uri)) return failedState;

    if (!loading.includes(uri)) {
        getRecipe(dispatch, uri);
    }

    return loadingState;
}
