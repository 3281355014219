import { IRouteType } from './types';

export const routes: IRouteType[] = [
    {
        name: 'Forside',
        path: '',
        icon: 'home',
        activeCondition: [],
        list: [
            {
                name: 'Forside',
                path: '',
                activeCondition: [/^\/$/],
                description: 'Gå til forsiden og bliv inspireret',
            },
        ],
    },
    {
        name: 'Opskrifter',
        path: '',
        icon: 'book',
        activeCondition: [/^\/opskrift($|\/)/],
        list: [
            {
                name: 'Alle opskrifter',
                path: 'opskrifter',
                activeCondition: [/^\/opskrifter$/],
                description: 'Se alle vores opskrifter',
            },
            {
                name: 'Billedopskrifter',
                path: 'opskrifter/billede',
                activeCondition: [/^\/opskrifter\/billede$/],
                description: 'Se alle vores billedopskrifter',
            },
            {
                name: 'Kategorier',
                path: 'opskrifter/kategorier',
                activeCondition: [/^\/opskrifter\/kategorier($|\/)/],
                description: 'Se alle vores billedopskrifter',
            },
        ],
    },
    {
        name: 'Ingredienser',
        path: '',
        icon: 'list-alt',
        activeCondition: [/^\/ingrediens($|\/)/],
        list: [
            {
                name: 'Alle ingredienser',
                path: 'ingredienser',
                activeCondition: [/^\/ingredienser$/],
                description: 'Se alle vores opskrifter',
            },
            {
                name: 'Konverter',
                path: 'ingredienser/konverter',
                activeCondition: [/^\/ingredienser\/konverter$/],
                description: 'Se alle vores opskrifter',
            },
            {
                name: 'Tøm køleskabet',
                path: 'ingredienser/køleskab',
                activeCondition: [
                    '/ingredienser/køleskab',
                    '/ingredienser/k%C3%B8leskab',
                ],
                description: 'Se alle vores opskrifter',
            },
        ],
    },
];

if (process.env.NODE_ENV === 'development') {
    routes.push({
        name: 'Showroom',
        path: 'showroom',
        icon: 'moon',
        activeCondition: [/^\/showroom($|\/)/],
        list: [
            {
                name: 'Showroom',
                path: 'showroom',
                activeCondition: [/^\/showroom($|\/)/],
                description: 'Gå til showroom',
            },
        ],
    });
}
