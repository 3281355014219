import { useSelector } from 'react-redux';
import { getState } from '../slice';
import { usePath } from 'hooks';
import { IPublicRecipe } from 'api/recipe';

export function useCurrentRecipe(): IPublicRecipe {
    const {
        paths: [, uri],
    } = usePath();

    const {
        recipes: { [uri]: recipe },
    } = useSelector(getState);

    return recipe;
}
