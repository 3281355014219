import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { reduced as navigation } from './navigation';
import { reduced as user } from './user';
import { reduced as layout } from './layout';
import { reduced as recipes } from '../features/recipe';
import { reduced as tooltips } from '../components/overlays/tooltip';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        ...user,
        ...navigation,
        ...layout,
        ...recipes,
        ...tooltips,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
