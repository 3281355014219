type ExpressionType = string | RegExp;

interface ITestString {
    (
        value: string,
        expressions: ExpressionType | ExpressionType[] | ExpressionType[][]
    ): boolean;
}

export const testString: ITestString = (value, expressions) => {
    if (Array.isArray(expressions)) {
        return expressions.some((expression: Parameters<ITestString>[1]) =>
            testString(value, expression)
        );
    }

    if (typeof expressions === 'string') {
        return expressions.toLowerCase() === value.toLowerCase();
    }

    return expressions.test(value);
};
