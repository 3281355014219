import { IConvertDispatch } from './typings.convert';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const actions = (dispatch: IConvertDispatch) => ({
    setCategoryType: (payload: Record<'categoryType', string>): void =>
        dispatch({
            type: 'setCategoryType',
            payload,
        }),
    setFirstSelectionType: (
        payload: Record<'firstSelectedType', string>
    ): void =>
        dispatch({
            type: 'setSelectionType',
            payload,
        }),
    setSecondSelectionType: (
        payload: Record<'secondSelectedType', string>
    ): void =>
        dispatch({
            type: 'setSelectionType',
            payload,
        }),
    setFirstSelectionValue: (
        payload: Record<'firstSelectedValue', number>
    ): void =>
        dispatch({
            type: 'setSelectionValue',
            payload,
        }),
    setSecondSelectionValue: (
        payload: Record<'secondSelectedValue', number>
    ): void =>
        dispatch({
            type: 'setSelectionValue',
            payload,
        }),
});
