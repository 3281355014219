import React from 'react';
import { CheckedList } from 'components/lists-tables/check-list';
import { Link } from 'utils';
import { getIngredients } from '../utils';
import { useCurrentRecipe } from '../hooks';

export function Ingredients() {
    const recipe = useCurrentRecipe();

    const ingredients = getIngredients(recipe);

    const list = ingredients.map(
        ({ name, measurementAmount, measurementType, url }) => (
            <>
                {measurementAmount} {measurementType}{' '}
                <Link
                    to={`/ingredienser/${url}`}
                    title={`Se alle opskrifter med ${name}`}
                >
                    {name}
                </Link>
            </>
        )
    );

    return <CheckedList list={list} />;
}
