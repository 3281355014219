import React, { forwardRef } from 'react';
import styles from './button.module.scss';
import { IChildren, IClassName } from 'typings';
import { classNames } from 'utils';

interface IButton extends IChildren, IClassName {
    type?: 'default' | 'primary' | 'dark';
    center?: boolean;
    onClick?: (value: any) => void;
    value?: any;
}

export const Button = forwardRef<HTMLButtonElement, IButton>(
    (
        {
            children,
            className,
            type = 'default',
            center,
            onClick,
            value,
        }: IButton,
        ref
    ) => {
        const handleClick = () => onClick?.(value);

        const buttonClassName = classNames(
            styles.button,
            styles[type],
            {
                [styles.center]: !!center,
            },
            className
        );

        return (
            <button ref={ref} className={buttonClassName} onClick={handleClick}>
                {children}
            </button>
        );
    }
);
