import React from 'react';
import { usePath } from 'hooks';
import { Ingredients } from './ingredients';
import { Convert } from './convert';
import { Fridge } from './fridge';

export function IngredientRoute() {
    const { paths } = usePath();

    switch (paths?.[1]) {
        case 'konverter': {
            return paths.length !== 2 ? <>Fejl!</> : <Convert />;
        }
        case 'køleskab': {
            return paths.length !== 2 ? <>Fejl!</> : <Fridge />;
        }
        default: {
            return <Ingredients />;
        }
    }
}
