import { getElementRect } from './get-element-rect';
import { getContentRect } from './get-content-rect';
import { IRect, IScrollRect } from './typings';
import {
    isPartiallyWithin,
    rectToRectPoints,
    getDistanceBeforePartiallyWithin,
} from 'utils/math/is-within';

export const isRectVisible = (
    rect: IRect,
    contentRect: IScrollRect
): boolean => {
    const box = rectToRectPoints(rect);
    const container = rectToRectPoints(contentRect);

    return isPartiallyWithin(box, container);
};

export const isRectVisibleWithDistance = (
    rect: IRect,
    contentRect: IScrollRect
): { visible: boolean; distance: number } => {
    const box = rectToRectPoints(rect);
    const container = rectToRectPoints(contentRect);

    if (isPartiallyWithin(box, container))
        return { visible: true, distance: 0 };

    return {
        visible: false,
        distance: getDistanceBeforePartiallyWithin(box, container),
    };
};

export const isVisible = (
    element: HTMLElement,
    overlay?: Partial<IRect>
): boolean => {
    const contentRect = getContentRect();
    const rect = getElementRect(element, contentRect);

    return isRectVisible(rect, {
        ...contentRect,
        top: overlay?.top ?? 0,
        left: overlay?.left ?? 0,
    });
};

export const isVisibleWithDistance = (
    element: HTMLElement,
    overlay?: Partial<IRect>
): { visible: boolean; distance: number } => {
    const contentRect = getContentRect();
    const rect = getElementRect(element, contentRect);

    return isRectVisibleWithDistance(rect, {
        ...contentRect,
        top: overlay?.top ?? 0,
        left: overlay?.left ?? 0,
    });
};
