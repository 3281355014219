import React, { ReactNode } from 'react';
import { IRecipeSection, IRecipeStep, IRecipeIngredient } from 'api/recipe';
import { styles } from '../styles';
import { NumberedList } from 'components/lists-tables/numbered-list';

const formatStep = ({ description, ingredients }: IRecipeStep): ReactNode => {
    const elements = description.match(/{[\w\d]{5}}|[^{]+/g) || [];
    const ingredientIdentifiers = ingredients.map(
        ({ identifier }) => `{${identifier}}`
    );

    const results = elements.map((element) => {
        if (!ingredientIdentifiers.includes(element)) {
            return element;
        }

        const {
            identifier,
            name,
            measurementAmount,
            measurementType,
        } = ingredients.find(
            ({ identifier }) => `{${identifier}}` === element
        ) as IRecipeIngredient;

        return (
            <span key={identifier} className={styles.inlineIngredient}>
                {measurementAmount} {measurementType} {name}
            </span>
        );
    });

    return results;
};

export function Section({ title, steps }: IRecipeSection) {
    const list = steps?.map(formatStep);

    return (
        <div key={title}>
            <h3>{title}</h3>
            <NumberedList list={list} />
        </div>
    );
}
