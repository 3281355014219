import React, { useEffect, useRef, ReactNode, useState } from 'react';
import styles from './tooltip.module.scss';
import { classNames, getElementDimensions } from 'utils';

interface ITooltipOverlay {
    content: ReactNode;
    position: {
        top: number;
        left: number;
        width: number;
        height: number;
    };
    placement: 'left' | 'right' | 'top' | 'bottom';
}

const spacing = 4;

type CoordinatesType = {
    top: number;
    left: number;
    placement: ITooltipOverlay['placement'];
};

function getCoordinates(
    dimensions: { width: number; height: number },
    position: ITooltipOverlay['position'],
    placement: ITooltipOverlay['placement'] | null,
    previousPlacements: ITooltipOverlay['placement'][] = []
): CoordinatesType {
    let calcForPlacement: ITooltipOverlay['placement'];

    if (placement) {
        calcForPlacement = placement;
    } else if (previousPlacements.indexOf('top') === -1) {
        calcForPlacement = 'top';
    } else if (previousPlacements.indexOf('right') === -1) {
        calcForPlacement = 'right';
    } else if (previousPlacements.indexOf('left') === -1) {
        calcForPlacement = 'left';
    } else if (previousPlacements.indexOf('bottom') === -1) {
        calcForPlacement = 'bottom';
    } else {
        calcForPlacement = 'top';
    }

    switch (calcForPlacement) {
        case 'top':
        default: {
            const newPosition: CoordinatesType = {
                top: position.top - dimensions.height - spacing,
                left: Math.round(
                    position.left + (position.width - dimensions.width) / 2
                ),
                placement: 'top',
            };

            if (
                newPosition.top < 0 &&
                previousPlacements.indexOf('top') === -1
            ) {
                return getCoordinates(dimensions, position, null, [
                    ...previousPlacements,
                    'top',
                ]);
            }

            return newPosition;
        }
        case 'bottom': {
            const newPosition: CoordinatesType = {
                top: position.top + position.height + spacing,
                left: Math.round(
                    position.left + (position.width - dimensions.width) / 2
                ),
                placement: 'bottom',
            };

            // Check for screen height

            return newPosition;
        }
        case 'right': {
            const newPosition: CoordinatesType = {
                top: Math.max(
                    0,
                    Math.round(
                        position.top + (position.height - dimensions.height) / 2
                    )
                ),
                left: position.left + position.width + spacing,
                placement: 'right',
            };

            if (
                newPosition.left < 0 &&
                previousPlacements.indexOf('right') === -1
            ) {
                return getCoordinates(dimensions, position, null, [
                    ...previousPlacements,
                    'right',
                ]);
            }

            return newPosition;
        }
        case 'left': {
            const newPosition: CoordinatesType = {
                top: Math.max(
                    0,
                    Math.round(
                        position.top + (position.height - dimensions.height) / 2
                    )
                ),
                left: position.left - dimensions.width - spacing,
                placement: 'left',
            };

            if (
                newPosition.left < 0 &&
                previousPlacements.indexOf('top') === -1
            ) {
                return getCoordinates(dimensions, position, null, [
                    ...previousPlacements,
                    'top',
                ]);
            }

            return newPosition;
        }
    }
}

export const TooltipOverlay = ({
    content,
    position,
    placement,
}: ITooltipOverlay) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [
        { placement: tooltipPlacement, ...tooltipPosition },
        setTooltipPosition,
    ] = useState<Partial<CoordinatesType>>({});

    useEffect(() => {
        if (tooltipRef.current) {
            const dimensions = getElementDimensions(tooltipRef.current);

            setTooltipPosition(getCoordinates(dimensions, position, placement));
        }
    }, [tooltipRef, content, position, placement]);

    return (
        <div
            ref={tooltipRef}
            style={tooltipPosition}
            className={classNames(
                styles.overlay,
                tooltipPlacement && styles[tooltipPlacement]
            )}
        >
            {content}
        </div>
    );
};
