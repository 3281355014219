import React from 'react';
import { classNames, matchTestString } from 'utils';
import styles from '../navigation.module.scss';
import { Icon } from 'components/images-icons/icon';
import { IRouteType } from '../types';
import { usePath } from 'hooks';

export const Categories = (
    route: IRouteType & {
        handleHoverCategory: (name: string) => void;
    }
) => {
    const { pathname } = usePath();

    const active = matchTestString(pathname, [
        route.activeCondition,
        ...route.list.map((item) => item.activeCondition),
    ]);

    const className = classNames(styles.category, {
        [styles.active]: active,
    });

    return (
        <li
            className={className}
            onMouseEnter={() => route.handleHoverCategory(route.name)}
        >
            <Icon className={styles.categoryIcon} icon={route.icon} />
            <div className={styles.categoryName}>{route.name}</div>
        </li>
    );
};
