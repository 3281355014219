import { IPosition } from './typings';

// https://stackoverflow.com/a/26230989/1059368
export function getScrollPosition(): IPosition {
    const { body, documentElement } = document;

    const scrollTop =
        window.pageYOffset || documentElement.scrollTop || body.scrollTop;
    const scrollLeft =
        window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

    const clientTop = documentElement.clientTop || body.clientTop || 0;
    const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

    return {
        top: Math.round(scrollTop - clientTop),
        left: Math.round(scrollLeft - clientLeft),
    };
}

export function getScrollPositionOfElement(element: Element): IPosition {
    const { scrollTop, scrollLeft, clientTop = 0, clientLeft = 0 } = element;

    return {
        top: Math.round(scrollTop - clientTop),
        left: Math.round(scrollLeft - clientLeft),
    };
}
