import { IConvertState } from '../typings.convert';
import { conversionTypes } from 'utils/ingredient/convert';

export const getSelectionTypes = (
    categoryType: string
): Pick<
    IConvertState,
    'categoryType' | 'categoryList' | 'firstSelectedType' | 'secondSelectedType'
> => {
    const categoryList = conversionTypes[categoryType].list;
    const selectedType = categoryList[0].key;

    return {
        categoryType,
        categoryList,
        firstSelectedType: selectedType,
        secondSelectedType: selectedType,
    };
};
