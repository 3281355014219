import { lazy } from 'react';
import { RoomType, ShowType } from './types';
import { list } from './list';
import { capitalizeFirstLetter } from 'utils';

type Keys = keyof typeof list;

const formatName = (name: string): string =>
    capitalizeFirstLetter(name.split('-').join(' '));

export const designRooms: ShowType[] = list['design'].map((item) => ({
    name: formatName(item),
    component: lazy(() => import(`components/design/${item}/show`)),
}));

const formatRoomName = (roomName: string): Keys =>
    roomName.replace(/( and )| /g, '-').toLowerCase() as Keys;

const getRooms = (name: string): RoomType => {
    const key = formatRoomName(name);

    return {
        name,
        list: list[key]?.map(
            (item: string): ShowType => ({
                name: formatName(item),
                component: lazy(() => import(`components/${key}/${item}/show`)),
            })
        ),
    };
};

export const rooms: RoomType[] = [
    getRooms('Actions'),
    getRooms('Structure'),
    getRooms('Forms'),
    getRooms('Images and icons'),
    getRooms('Feedback indicators'),
    getRooms('Titles and text'),
    getRooms('Behavior'),
    getRooms('Lists and tables'),
    getRooms('Navigation'),
    getRooms('Overlays'),
];
