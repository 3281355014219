type Rect = { top: number; left: number; width: number; height: number };
type RectPoints = { x1: number; y1: number; x2: number; y2: number };

interface IWithin {
    (box: RectPoints, container: RectPoints, allowedDistance?: number): boolean;
}

interface IDistance {
    (box: RectPoints, container: RectPoints, allowedDistance?: number): number;
}

const orderCoordinates = (rect: RectPoints): RectPoints => ({
    x1: Math.min(rect.x1, rect.x2),
    y1: Math.min(rect.y1, rect.y2),
    x2: Math.max(rect.x1, rect.x2),
    y2: Math.max(rect.y1, rect.y2),
});

export const isPartiallyWithin: IWithin = (
    box,
    container,
    allowedDistance = 0
) => {
    box = orderCoordinates(box);
    container = orderCoordinates(container);

    if (box.y2 < container.y1 - allowedDistance) return false; // 1 is above 2
    if (box.y1 > container.y2 + allowedDistance) return false; // 1 is below 2
    if (box.x2 < container.x1 - allowedDistance) return false; // 1 is left of 2
    if (box.x1 > container.x2 + allowedDistance) return false; // 1 is right of 2

    return true;
};

export const isFullyWithin: IWithin = (box, container, allowedDistance = 0) => {
    box = orderCoordinates(box);
    container = orderCoordinates(container);

    if (box.y1 < container.y1 - allowedDistance) return false;
    if (box.y2 > container.y2 + allowedDistance) return false;
    if (box.x1 < container.x1 - allowedDistance) return false;
    if (box.x2 > container.x2 + allowedDistance) return false;

    return true;
};

export const getDistanceBeforePartiallyWithin: IDistance = (
    box,
    container,
    allowedDistance = 0
) => {
    box = orderCoordinates(box);
    container = orderCoordinates(container);

    if (box.y2 < container.y1 - allowedDistance)
        return Math.abs(container.y1 - allowedDistance - box.y2);
    if (box.y1 > container.y2 + allowedDistance)
        return Math.abs(container.y2 + allowedDistance - box.y1);
    //if (box.x2 < container.x1 - allowedDistance) return false;
    //if (box.x1 > container.x2 + allowedDistance) return false;

    return 0;
};

export const rectToRectPoints = (rect: Rect): RectPoints => ({
    x1: rect.left,
    y1: rect.top,
    x2: rect.width + rect.left,
    y2: rect.height + rect.top,
});
